import { Component, HostBinding, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select } from '@angular-redux/store';
import { Observable } from 'rxjs';
import { ClientsService } from 'src/app/services/clients/clients.service';
import { CommandesService } from 'src/app/services/commandes/commandes.service';
import { ThemeOptions } from 'src/app/theme-options';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-top',
  templateUrl: './top.component.html',
  styleUrls: ['./top.component.css']
})
export class TopComponent implements OnInit {

  @select('config') public config$: Observable<any>;

  private newInnerWidth: number;
  private innerWidth: number;

  isConnected = false
  client: any = null
  allComments: any = []

  lang: any
  showList: any = false;
  selectedBox: any
  langauges: any[] = [
    {
      img: "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse1.mm.bing.net%2Fth%3Fid%3DOIP.kl7GhvZW1_zkEFGgwpR9iAHaE8%26pid%3DApi&f=1&ipt=db85b4ca3ecf02bd95b525e19aaad1ac68dcebe9515b560f9a05d75f87dfcb31&ipo=images",
      lang: "data.frensh",
      val: "fr"
    },
    {
      img: "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse3.mm.bing.net%2Fth%3Fid%3DOIP.e_bbO_MwobphE7AiIzUzyQHaEA%26pid%3DApi&f=1&ipt=78a47fd578257f340440ce8dc9ff2af23e0926ad8ef80d62e293785c75848053&ipo=images",
      lang: "data.english",
      val: "en"
    }
  ]

  constructor(private router: Router,
    private translate: TranslateService,
    private cmdsService: CommandesService,
    private clientService: ClientsService,
    public globals: ThemeOptions,) {
    this.isConnected = this.clientService.isConnected()
    
    this.initLanguageTranslate()
  }

  @HostBinding('class.isActive')
  get isActiveAsGetter() {
    return this.isActive;
  }

  isActive: boolean;

  async ngOnInit() {
    setTimeout(() => {
      this.innerWidth = window.innerWidth;
      if (this.innerWidth < 1200) {
        this.globals.toggleSidebar = true;
      }
    });

    await this.getInfosUser()
    await this.fetchComments()
  }

  // lang = localStorage.getItem('lang');
  selectLanguage(event: any) {
    // localStorage.setItem('lang', event.target.value);

    this.clientService.setLanguage(event.target.value)
    this.translate.use(event.target.value);
  }

  initLanguageTranslate() {
    this.lang = this.clientService.getLanguage()// localStorage.getItem('lang');
    // console.log(this.lang)
    this.selectedBox = this.langauges.find(item => item?.val === this.lang)
    this.translate.setDefaultLang((this.lang) ? this.lang : 'en');
    // if (!lang) {
    //   this.translate.setDefaultLang('en');
    // } else {
    //   this.translate.setDefaultLang(lang);
    // }
  }

  up() {
    this.showList = !this.showList;
  }

  change(box: any) {
    this.selectedBox = box
    this.clientService.setLanguage(this.selectedBox.val)
    this.translate.use(this.selectedBox.val)
    this.showList = !this.showList;
  }

  async getInfosUser() {
    await this.clientService.getUserInfos().then((res: any) => {
      //console.log("getUserInfos");
      //console.log(res);
      if (res) {
        this.client = res;
      }
    });
  }

  async fetchComments() {
    await this.cmdsService.allComments(this.client._id).then((res: any) => {
      // console.log("fetchComments");
      // console.log(res);
      if (res) {
        this.allComments = res;
      }
    });
  }

  async deconnexion() {
    await this.clientService.deconnexion()
    // this.router.navigate(['/home'])
    window.location.reload()
    this.router.navigate(['/login'])
  }

  toggleSidebar() {
    // console.log("toggleSidebar")
    // console.log(this.globals.toggleSidebar)
    this.globals.toggleSidebar = !this.globals.toggleSidebar;
  }

  sidebarHover() {
    // console.log("sidebarHover")
    // console.log(this.globals.sidebarHover)
    this.globals.sidebarHover = !this.globals.sidebarHover;
    // if(this.globals.sidebarHover) {
    //   this.globals.toggleSidebar = true
    //   this.globals.sidebarHover = false
    // }
  }

  toggleSidebarMobile() {
    // console.log("toggleSidebarMobile")
    // console.log(this.globals.toggleSidebarMobile)
    this.globals.toggleSidebarMobile = !this.globals.toggleSidebarMobile;
    if (this.globals.toggleSidebarMobile) {
      this.globals.toggleHeaderMobile = false
    }
  }

  toggleHeaderMobile() {
    // console.log("toggleHeaderMobile")
    this.globals.toggleHeaderMobile = !this.globals.toggleHeaderMobile;
    if (this.globals.toggleHeaderMobile) {
      this.globals.toggleSidebarMobile = false
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.newInnerWidth = event.target.innerWidth;

    if (this.newInnerWidth < 1200) {
      this.globals.toggleSidebar = true;
    } else {
      this.globals.toggleSidebar = false;
    }

  }

}
