import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { ClientsService } from 'src/app/services/clients/clients.service';
import { CommandesService } from 'src/app/services/commandes/commandes.service';

@Component({
  selector: 'app-commandes',
  templateUrl: './commandes.component.html',
  styleUrls: ['./commandes.component.css']
})
export class CommandesComponent implements OnInit {

  isLoading: any = true;
  status = ["Selectionnez status", "validated", "transporting_depot", "prepared", "submited", "transporting", "transported", "arrived", "delivered", "paied", "returned", "refused", "canceled"];

  currentPage: number = 0
  totalPages: number = 1
  pages: Array<number> = [];
  pagesDisplaied: Array<number> = [];

  allSocieties: any[] = []

  cmdList: Array<any> = [];
  statusCmds: any = []
  client: any = null

  filter: any = {}

  constructor(private cmdsService: CommandesService,
    private translate: TranslateService,
    private clientService: ClientsService) { }

  async ngOnInit() {
    this.cmdsService.getLabeledStatus().then(
      (lst) => {
        this.statusCmds = lst
      }
    )

    await this.getInfosUser()
    await this._fetchAllSocieties()
    // this.listCmdsClient()
    this.searchCmds()
  }

  async filterCmds() {
    // console.log(this.filter)
    this.pages = []
    this.pagesDisplaied = []
    this.currentPage = 0
    this.searchCmds() //ref.value, numero.value, datein.value, datefin.value);
  }

  async searchCmds() {
    // let userId = await this.clientService.getIdUser()
    this.filter.page = this.currentPage
    await this.cmdsService.filterCmds(this.filter).then((res: any) => {
      if (res) {
        this.isLoading = false
        // console.log("resssssssss : ")
        // console.log(res)
        this.cmdList = res.items;
        this.totalPages = res.totalPages
        this.currentPage = res.currentPage
        this.pages = Array.from(Array(res.totalPages)).map((x, i) => i)
        this.splicePages()
      }
    });
  }

  goToNewTab(idCmd, route) {
    this.cmdsService.goToNewTab(idCmd, route)
  }

  selectChangeStatus(event: any) {
    if (event.target.value == "Selectionnez status") {
      this.filter.status = null
    } else {
      this.filter.status = event.target.value;
    }
  }

  async getInfosUser() {
    await this.clientService.getUserInfos().then((res: any) => {
      // console.log("getUserInfos");
      // console.log(res);
      if (res) {
        this.client = res;
      }
    });
  }

  // async listCmdsClient() {
  //   this.pages = []
  //   let userId = await this.clientService.getIdUser()
  //   await this.cmdsService.getCmdsByClient(userId).then((res: any) => {
  //     console.log(res)
  //     if (res) {
  //       this.cmdList = res;
  //     }
  //   });
  // }

  splicePages() {
    // console.log("currentPage : ")
    //     console.log(this.currentPage)
    if (this.pages.length > 10) {
      // if(this.currentPage < this.pages.length-10) {
      //   this.pagesDisplaied = this.pages.splice(this.currentPage, 10);
      //   console.log("if 1 pagesDisplaied : ")
      //   console.log(this.pagesDisplaied)
      // } else 
      if (this.currentPage < 5) {
        this.pagesDisplaied = this.pages.splice(0, 10);
        // console.log("if 1 pagesDisplaied : ")
        // console.log(this.pagesDisplaied)
      } else if (this.currentPage >= 5 && this.currentPage < this.pages.length - 5) {
        this.pagesDisplaied = this.pages.splice(this.currentPage - 4, 10);
        // console.log("if 2 pagesDisplaied : ")
        // console.log(this.pagesDisplaied)
      } else {
        this.pagesDisplaied = this.pages.splice(this.pages.length - 10, 10);
        // console.log("else pagesDisplaied : ")
        // console.log(this.pagesDisplaied)
      }
    } else {
      this.pagesDisplaied = this.pages
    }
  }

  nextPage() {
    this.currentPage++
    this.searchCmds() //ref.value, numero.value, datein.value, datefin.value);
  }

  previousPage() {
    this.currentPage--
    this.searchCmds() //ref.value, numero.value, datein.value, datefin.value);
  }

  selectPage(page) {
    this.currentPage = page
    this.searchCmds() //ref.value, numero.value, datein.value, datefin.value);
  }
  
  deleteCmd(idCmd: any) {
    this.translate.get('data.are_you_sure').subscribe(async (text: string) => {
      if (confirm(text)) {
        await this.cmdsService.deleteCmd(idCmd).then((res) => {
          if (res) {
            this.translate.get('data.order_updated_successfully')
              .subscribe(async (text: string) => {
                alert(text)
              });
            // cmd.etat_cmd = "canceled"
            this.searchCmds()
          }
          else {
            this.translate.get('data.an_error_occurred')
              .subscribe(async (text: string) => {
                alert(text)
              });
          }
        });
      }
    });
  }

  updateStatusCmd(cmd, status) {
    this.translate.get('data.are_you_sure').subscribe(async (text: string) => {
      if (confirm(text)) {
        await this.cmdsService.updateCmdStatus(cmd, status).then((res) => {
          if (res) {
            this.translate.get('data.order_updated_successfully')
              .subscribe(async (text: string) => {
                alert(text)
              });
            // cmd.etat_cmd = "canceled"
            this.searchCmds()
          }
          else {
            this.translate.get('data.an_error_occurred')
              .subscribe(async (text: string) => {
                alert(text)
              });
          }
        });
      }
    });
    // this.notif.notify('success', 'Commande mis à jour avec succès');
    // return;

  }

  /** BEGIN FUNCTION SOCIETY */
  async _fetchAllSocieties() {
    await this.cmdsService.allSocieties().then((res: any) => {
      // console.log("resssssssss services : ")
      // console.log(res)
      if (res) {
        this.allSocieties = res;
      }
    });
  }
  /** END FUNCTION SOCIETY */

}
