<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-box2 icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>{{'data.pickup' | translate }}
                <div class="page-title-subheading">{{'data.pickup_detail' | translate }}.
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="isLoading; else elseDetailsCmd">
    <div class="row">
        <div class="col-md-12">
            <div class="main-card mb-3 card">
                <div class="card-body text-center">
                    <div class="spinner-container blue-spinner">
                        <mat-progress-spinner mode="indeterminate" [diameter]="40"
                            [strokeWidth]="5"></mat-progress-spinner>
                    </div>
                </div>
                <!-- <div style="height: 70px;" [nbSpinner]="loading" nbSpinnerStatus="warning" nbSpinnerSize="giant">
            </div> -->
            </div>
        </div>
    </div>
</div>
<ng-template #elseDetailsCmd>

    <div class="row">
        <div class="col-md-12">
            <div class="main-card mb-3 card">
                <div class="tab-content" id="tab-content">
                    <div class="main-card mb-12 card">
                        <div class="card-body">
                            <div id="commande" #commande>
                                <div class="row">
                                    <div class="col-md-7">
                                        <div class="text space">
                                            <h4><strong>{{'data.pickup' | translate }}</strong></h4>
                                        </div>
                                    </div>
                                    <div class="col-md-5 text-md-right">
                                        <h4><strong>#{{cmd.ref_facture_cmd}}</strong></h4>
                                        <!-- <qrcode print [width]="150" usesvg="true" [elementType]="'svg'" [qrdata]="cmd._id"
                            [errorCorrectionLevel]="'M'"></qrcode> -->
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-7">
                                        <div class="text space">
                                            <qrcode print [width]="150" usesvg="true" [elementType]="'svg'"
                                                [qrdata]="cmd._id" [errorCorrectionLevel]="'M'">
                                            </qrcode>
                                        </div>
                                    </div>
                                    <div class="col-md-5 text-md-left">
                                        <strong>{{'data.company' | translate }}:</strong><br /><br />
                                        <div class="widget-content p-0">
                                            <div class="widget-content-wrapper">
                                                <div class="widget-content-left mr-3">
                                                    <div class="widget-content-left">
                                                        <img width="40" class="rounded-circle"
                                                            [src]="cmd?.society?.logo" alt="Logo"
                                                            style="border: 1px solid #000;">
                                                    </div>
                                                </div>
                                                <div class="widget-content-left flex2">
                                                    <div class="widget-heading">
                                                        {{cmd?.society?.name}}
                                                    </div>
                                                    <div class="widget-subheading opacity-7">
                                                        {{cmd?.society?.pays}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div><br />
                                        <b>{{'data.email' | translate}}: </b>{{cmd?.society?.email}}<br />
                                        <b>{{'data.website' | translate}}: </b>{{cmd?.society?.web_site}}<br />
                                        <b>{{'data.phone' | translate}}: </b>{{cmd?.society?.num_tel}}<br />
                                        <b>{{'data.address' | translate}}: </b>{{cmd?.society?.adress?.label_target}}<br /><br />

                                        <strong>{{'data.pickup_date' | translate }}:</strong><br />
                                        {{cmd.date_creation_cmd | date:'dd/MM/yyyy'}}<br />
                                        <div class="badge badge-{{statusCmds[cmd.etat_cmd]?.color}}">
                                            {{statusCmds[cmd.etat_cmd]?.label}}
                                        </div>
                                    </div>
                                </div>

                                <div class="clearfix"></div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <!-- <div class="main-card mb-3 card"> -->
                                        <div class="section-title" *ngIf="cmd?.boxes?.length > 0">
                                            <!-- Détails de la commande -->
                                            {{'data.boxes' | translate }}
                                        </div>

                                        <div class="table-responsive">
                                            <!-- <div class="budget-price justify-content-center"
                                            *ngIf="cmd?.boxes?.length === 0">
                                            <h2 class="text-center" style="color: red;">
                                                Aucun box affecté dans la commande
                                            </h2>
                                        </div> -->
                                            <table
                                                class="align-middle mb-0 table table-borderless table-striped table-hover"
                                                *ngIf="cmd?.boxes?.length > 0">
                                                <thead>
                                                    <tr>
                                                        <th class="text-center">#</th>
                                                        <th class="text-left">{{'data.product_name' | translate}}</th>
                                                        <th class="text-left">{{'data.product_designation' | translate }}</th>
                                                        <th class="text-center">{{'data.volume' | translate }} ({{'data.cubic_meters' | translate }})</th>
                                                        <th class="text-center">{{'data.carton_weight' | translate }} ({{'data.kg' | translate }})</th>
                                                        <!-- <th class="text-center">Prix unitaire</th> -->
                                                        <th class="text-center">{{'data.quantity' | translate }}</th>
                                                        <!-- <th class="text-center">Montant</th> -->
                                                        <th class="text-center">{{'data.total_volume' | translate }} ({{'data.cubic_meters' | translate }})</th>
                                                        <th class="text-center">{{'data.total_weight' | translate }} ({{'data.kg' | translate }})</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let prd of cmd?.boxes, let index = index">
                                                        <td class="text-center text-muted">#{{index+1}}</td>
                                                        <td class="text">{{prd.nom_prod}}<br>
                                                            <!-- <b *ngIf="cmd.isExchange==true">Echange ({{prd.quantite}}
                                                            article(s))</b> -->
                                                        </td>
                                                        <td class="text">{{prd.des_prod}}</td>
                                                        <td class="text-center">{{prd.volume | number:'0.1'}}</td>
                                                        <td class="text-center">{{prd.weight | number:'0.1'}}</td>
                                                        <!-- <td class="text-center">{{prd.prix_unitaire}} DT
                                                    </td> -->
                                                        <td class="text-center">{{prd.quantite}}</td>
                                                        <td class="text-center">{{prd.quantite * prd?.volume |
                                                            number:'0.1'}}</td>
                                                        <td class="text-center">{{prd.quantite * prd?.weight |
                                                            number:'0.1'}}</td>
                                                        <!-- <td class="text-center">
                                                        {{prd.quantite*prd.prix_unitaire}} DT</td> -->
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <!-- </div> -->
                                    </div>

                                    <div class="col-md-12" *ngIf="cmd?.genPallets?.length > 0">
                                        <div class="container" style="margin-top: 15px;">
                                            <div class="row">
                                                <div class="col-12 col-md-4" *ngFor="let pallet of cmd?.genPallets">
                                                    <div class="pallet" style="margin: 5px;">
                                                        <div class="pallet-info">
                                                            <h3>{{'data.pallet' | translate }} {{pallet?.palletNumber}}:</h3>
                                                            <p>{{'data.pallet_ratio' | translate }}: {{pallet?.dimensions?.width |
                                                                number:'0.1'}} X
                                                                {{pallet?.dimensions?.depth | number:'0.1'}} X
                                                                {{pallet?.dimensions?.height |
                                                                number:'0.1'}} m</p>
                                                            <p>{{'data.total_boxes' | translate }}: {{pallet?.countBoxes}}</p>
                                                            <div>
                                                                <button class="expand-btn">▼</button>
                                                                <p>{{'data.total_layer' | translate }}: {{pallet?.layers?.length}}</p>
                                                                <ul class="product-id-list">
                                                                    <li
                                                                        *ngFor="let layer of pallet?.layers; let i = index">
                                                                        {{'data.layer' | translate }} {{layer?.layerNumber}}: (H)
                                                                        {{layer?.layerHeight}}
                                                                        <div class="row">
                                                                            <div class="col-12 col-md-6"
                                                                                *ngFor="let item of layer?.boxes">
                                                                                {{ '(' + item?.count + ') - ' +
                                                                                item?.box?.nom_prod }}</div>
                                                                        </div>
                                                                    </li>
                                                                    <!-- <li>Layer 1: (11, 12, 13, 14)</li> -->
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="clearfix"></div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <!-- <div class="main-card mb-3 card"> -->
                                        <div class="section-title" *ngIf="cmd?.pallets?.length > 0">
                                            {{'data.pallets' | translate }}
                                        </div>

                                        <div class="table-responsive">
                                            <!-- <div class="budget-price justify-content-center"
                                            *ngIf="cmd?.pallets?.length === 0">
                                            <h5 class="text-center">Aucune palette affecté dans la
                                                commande
                                            </h5>
                                        </div> -->
                                            <table
                                                class="align-middle mb-0 table table-borderless table-striped table-hover"
                                                *ngIf="cmd?.pallets?.length > 0">
                                                <thead>
                                                    <tr>
                                                        <th class="text-center">#</th>
                                                        <th class="text-left">{{'data.name_of_the_pallet' | translate }}</th>
                                                        <th class="text-center">{{'data.volume' | translate }} ({{'data.cubic_meters' | translate }})</th>
                                                        <th class="text-center">{{'data.pallet_weight' | translate }} ({{'data.kg' | translate }})</th>
                                                        <!-- <th class="text-center">Prix unitaire</th> -->
                                                        <th class="text-center">{{'data.quantity' | translate }}</th>
                                                        <!-- <th class="text-center">Montant</th> -->
                                                        <th class="text-center">{{'data.total_volume' | translate }} ({{'data.cubic_meters' | translate }})</th>
                                                        <th class="text-center">{{'data.total_weight' | translate }} ({{'data.kg' | translate }})</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <ng-container
                                                        *ngFor="let pallet of cmd?.pallets, let index = index">
                                                        <tr>
                                                            <td class="text-center text-muted">#{{index+1}}</td>
                                                            <td class="text">{{pallet?.name_pallet}}<br>
                                                                <!-- <b *ngIf="cmd.isExchange==true">Echange ({{pallet?.quantite}}
                                                                article(s))</b> -->
                                                            </td>
                                                            <td class="text-center">{{pallet?.volume | number:'0.1'}}
                                                            </td>
                                                            <td class="text-center">{{pallet?.weight | number:'0.1'}}
                                                            </td>
                                                            <!-- <td class="text-center">{{pallet?.prix_unitaire}} DT
                                                        </td> -->
                                                            <td class="text-center">{{pallet?.quantity}}</td>
                                                            <!-- <td class="text-center">
                                                            {{pallet?.quantity*pallet?.prix_unitaire}} DT</td> -->
                                                            <td class="text-center">{{pallet.quantity * pallet?.volume |
                                                                number:'0.1'}}
                                                            </td>
                                                            <td class="text-center">{{pallet.quantity * pallet?.weight |
                                                                number:'0.1'}}
                                                            </td>
                                                        </tr>
                                                        <ng-container *ngIf="pallet?.boxes?.length > 0">
                                                            <tr style="background-color: #ecebeb;"
                                                                *ngFor="let box of pallet?.boxes, let index = index">
                                                                <td colspan="1"></td>
                                                                <td class="text">
                                                                    <div class="widget-heading">
                                                                        {{box?.nom_prod}}
                                                                    </div>
                                                                    <div class="widget-subheading opacity-7">
                                                                        {{box?.des_prod}}
                                                                    </div>
                                                                </td>
                                                                <td class="text-center">{{box?.volume | number:'0.1'}}
                                                                </td>
                                                                <td class="text-center">{{box?.weight | number:'0.1'}}
                                                                </td>
                                                                <!-- <td class="text-center">{{box?.prix_unitaire}} DT
                                                            </td> -->
                                                                <td class="text-center">{{box?.quantite}}</td>
                                                                <td class="text-center">{{box.quantite * box?.volume |
                                                                    number:'0.1'}}</td>
                                                                <td class="text-center">{{box.quantite * box?.weight |
                                                                    number:'0.1'}}</td>
                                                                <!-- <td class="text-center">
                                                                {{box?.quantite*box?.prix_unitaire}} DT</td> -->
                                                            </tr>
                                                        </ng-container>
                                                    </ng-container>
                                                </tbody>
                                            </table>
                                        </div>
                                        <!-- </div> -->
                                    </div>
                                </div>

                                <div class="clearfix"></div>

                                <div class="row mt-4">
                                    <div class="col-lg-8">
                                        <div class="section-title">{{'data.delivery_mode' | translate}}</div>
                                        <p class="section-lead">
                                            {{'data.home_delivery' | translate}}
                                            <br />→ {{cmd.destination_cmd}}
                                        </p>
                                    </div>
                                    <div class="col-lg-4 text-right">
                                        <div class="invoice-detail-item">
                                            <!-- <div class="invoice-detail-name">
                                            Prix des article(s):
                                            {{cmd.total_ca_cmd |
                                            number:'0.3'}} TND</div>
                                        <div class="invoice-detail-name">Frais de livraison:
                                            {{cmd.frais_livraison | number:'0.3'}} TND
                                        </div> 
                                        <div class="invoice-detail-value">Montant total:
                                            {{cmd.total_ca_cmd+cmd.frais_livraison |
                                            number:'0.3'}} TND
                                        </div> -->

                                            <div class="invoice-detail-value">{{'data.total_volume' | translate }}:
                                                {{cmd?.totalPalletVolume | number: '0.1'}} {{'data.cubic_meters' | translate }}
                                            </div>
                                            <div class="invoice-detail-value">{{'data.total_weight' | translate }}:
                                                {{cmd?.totalPoids | number: '0.1'}} {{'data.kg' | translate }}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="d-block text-center card-footer">
                            <!-- <button class="mr-2 btn-icon btn-icon-only btn btn-outline-danger"><i
                                class="pe-7s-trash btn-icon-wrapper"> </i></button> -->

                            <!-- <button class="btn-wide btn btn-success">Save</button> -->
                            <button class="btn btn-danger float-left" [routerLink]="[ '/commandes']"><i
                                    class="metismenu-icon pe-7s-back"></i> {{'data.return' | translate}}</button>

                            <!-- <button class="btn btn-warning float-right text-white"
                                (click)="goToNewTab(cmd._id, '/facture')"><i
                                    class="metismenu-icon pe-7s-print text-white"></i> {{'data.print' | translate}}</button> -->
                            <!-- <button *ngIf="cmd.etat_cmd=='delivered' && cmd.payed_cmd==false"
                                class="btn btn-success float-right" [routerLink]="[ '/exchange-cmd']"
                                [queryParams]="{ cmd: cmd._id }"><i class="metismenu-icon pe-7s-back"></i>
                                Echange</button> -->
                            <!-- <button class="btn btn-warning float-right" (click)="generatePDF()"><i
                                class="metismenu-icon pe-7s-print"></i> Imprimer</button> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="cmd?.uploadDocs?.length > 0">
        <div class="col-md-12">
            <div class="main-card mb-3 card">
                <div class="card-header-tab card-header">
                    <div class="card-header-title">
                        <i class="header-icon lnr-rocket icon-gradient bg-tempting-azure"> </i>
                        {{'data.uploaded_files' | translate}}
                    </div>
                    <div class="btn-actions-pane-right">
                    </div>
                </div>
                <div class="card-body">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 col-md-3 text-center" *ngFor="let file of cmd?.uploadDocs">
                                <a [href]="env?.uploadedFilesAssetsBaseURL + file" download target="_blank">{{ file }}</a>

                                <!-- <a (click)="downloadFile(file)" download>{{ file }}</a> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <div class="main-card mb-3 card">
                <div class="card-header">{{'data.comments' | translate}}</div>
                <div class="card-body">
                    <div class="chat-container">
                        <ul class="chat-box chatContainerScroll">
                            <!-- <li class="chat-left">
                            <div class="chat-avatar">
                                <img src="https://www.bootdey.com/img/Content/avatar/avatar3.png"
                                    alt="Retail Admin">
                                <div class="chat-name">Russell</div>
                            </div>
                            <div class="chat-text">Hello, I'm Russell.
                                <br>How can I help you today?
                            </div>
                            <div class="chat-hour">08:55 <span class="fa fa-check-circle"></span></div>
                        </li>
                        <li class="chat-right">
                            <div class="chat-hour">08:56 <span class="fa fa-check-circle"></span></div>
                            <div class="chat-text">Hi, Russell
                                <br> I need more information about Developer Plan.
                            </div>
                            <div class="chat-avatar">
                                <img src="https://www.bootdey.com/img/Content/avatar/avatar3.png"
                                    alt="Retail Admin">
                                <div class="chat-name">Sam</div>
                            </div>
                        </li>
                        <li class="chat-left">
                            <div class="chat-avatar">
                                <img src="https://www.bootdey.com/img/Content/avatar/avatar3.png"
                                    alt="Retail Admin">
                                <div class="chat-name">Russell</div>
                            </div>
                            <div class="chat-text">Are we meeting today?
                                <br>Project has been already finished and I have results to show you.
                            </div>
                            <div class="chat-hour">08:57 <span class="fa fa-check-circle"></span></div>
                        </li>
                        <li class="chat-right">
                            <div class="chat-hour">08:59 <span class="fa fa-check-circle"></span></div>
                            <div class="chat-text">Well I am not sure.
                                <br>I have results to show you.
                            </div>
                            <div class="chat-avatar">
                                <img src="https://www.bootdey.com/img/Content/avatar/avatar5.png"
                                    alt="Retail Admin">
                                <div class="chat-name">Joyse</div>
                            </div>
                        </li>
                        <li class="chat-left">
                            <div class="chat-avatar">
                                <img src="https://www.bootdey.com/img/Content/avatar/avatar3.png"
                                    alt="Retail Admin">
                                <div class="chat-name">Russell</div>
                            </div>
                            <div class="chat-text">The rest of the team is not here yet.
                                <br>Maybe in an hour or so?
                            </div>
                            <div class="chat-hour">08:57 <span class="fa fa-check-circle"></span></div>
                        </li> -->
                            <div *ngFor="let comment of cmd.comments, let index = index">

                                <li class="chat-right" *ngIf="comment.id_user == client._id">
                                    <div class="chat-hour">{{comment.date_ajout | date:'d MMM y HH:mm'}}
                                        <span class="fa fa-check-circle"></span>
                                    </div>
                                    <div class="chat-text">{{comment.content}}</div>
                                    <div class="chat-avatar">
                                        <!-- <img src="https://www.bootdey.com/img/Content/avatar/avatar4.png"
                                        alt="Retail Admin"> -->
                                        <ngx-avatar width="42" class="rounded-circle" name="{{comment?.name_user}}">
                                        </ngx-avatar>
                                        <div class="chat-name">{{comment.name_user}}</div>
                                    </div>
                                </li>

                                <li class="chat-left" *ngIf="comment.id_user !== client?._id">
                                    <div class="chat-avatar">
                                        <ngx-avatar width="42" class="rounded-circle" name="{{comment?.name_user}} ">
                                        </ngx-avatar>
                                        <div class="chat-name">{{comment.name_user}}</div>
                                    </div>
                                    <div class="chat-text">{{comment.content}}</div>
                                    <div class="chat-hour">{{comment.date_ajout | date:'d MMM y HH:mm'}}
                                        <span class="fa fa-check-circle"></span>
                                    </div>
                                </li>

                            </div>

                        </ul>
                        <div class="form-group mt-3 mb-0">
                            <form class="" action="" method="post" onsubmit="return false;"
                                (onsubmit)="addComment(comment);">
                                <div class="form-row">
                                    <div class="col-md-1">
                                        <div class="position-relative form-group">
                                            <ngx-avatar width="42" class="rounded-circle"
                                                name="{{client?.prenom_client + ' ' + client?.nom_client}}">
                                            </ngx-avatar>
                                        </div>
                                        <!-- {{client?.prenom_client +" "+client?.nom_client}} -->
                                    </div>
                                    <div class="col-md-10">
                                        <div class="position-relative form-group">
                                            <textarea name="comment" id="comment" class="form-control" rows="2"
                                                placeholder="Ajouter un commentaire..." #comment ngModel></textarea>
                                        </div>
                                    </div>
                                    <div class="col-md-1">
                                        <div class="position-relative form-group">
                                            <button type="submit" (click)="addComment(comment)"
                                                class="form-control btn btn-success">{{'data.send' | translate}}</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-8">
            <div class="main-card mb-3 card">
                <div class="card-header">{{'data.pickup_detail' | translate}} "<strong>{{cmd.ref_facture_cmd}}</strong>"
                    <div class="btn-actions-pane-right">
                        <div role="group" class="btn-group-sm btn-group">
                            <button type="button" id="PopoverCustomT-1" class="btn btn-danger btn-sm">{{'data.details' | translate}} <i
                                    class="metismenu-icon pe-7s-angle-right"></i></button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="align-middle mb-0 table table-borderless table-striped table-hover">
                            <thead>
                                <tr>
                                    <th>{{'data.status' | translate}}</th>
                                    <th>{{'data.description' | translate }}</th>
                                    <th>{{'data.date_of_action' | translate}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of cmd?.actions_cmd, let index = index">
                                    <td>
                                        <div class="badge badge-{{statusCmds[item?.label].color}}">
                                            {{statusCmds[item?.label]?.label}}</div>
                                    </td>
                                    <td class="font-weight-600">{{'data.by' | translate}}
                                        {{item?.user}}
                                    </td>
                                    <td>{{item?.action_date | date:'MMM d, y h:mm:ss a'}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="row" *ngIf="cmd?.appel_client?.length>0">
        <div class="col-md-8">
            <div class="main-card mb-3 card">
                <div class="card-header">Détails des appels de la commande
                    "<strong>{{cmd.ref_facture_cmd}}</strong>"
                    <div class="btn-actions-pane-right">
                        <div role="group" class="btn-group-sm btn-group">
                            <button type="button" id="PopoverCustomT-1"
                                class="btn btn-danger btn-sm">{{cmd.appel_client.length}}</button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="align-middle mb-0 table table-borderless table-striped table-hover">
                            <thead>
                                <tr>
                                    <th>Indice</th>
                                    <th>Transporteur</th>
                                    <th>Date de l'appel</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let appel of cmd.appel_client, let index = index">
                                    <td>
                                        {{index+1}}
                                    </td>
                                    <td class="font-weight-600">Par
                                        {{appel.transporter_id?.nom_prenom_tracking}}</td>
                                    <td>{{appel.date_accept | date:'MMM d, y h:mm:ss a'}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </div>
    </div> -->
</ng-template>