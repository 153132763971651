import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DetaisVirementComponent } from 'src/app/components/shared/dialogs/detais-virement/detais-virement.component';
import { ClientsService } from 'src/app/services/clients/clients.service';

@Component({
  selector: 'app-virements-client',
  templateUrl: './virements-client.component.html',
  styleUrls: ['./virements-client.component.css']
})
export class VirementsClientComponent implements OnInit {

  @ViewChild('goUp', {static: false}) contentPage: ElementRef;

  client: any = null
  listVirements: any = []
  selectedVirement: number = -1

  constructor(
    private clientService: ClientsService,
    private dialog: MatDialog) { }

  async ngOnInit() {
    await this.getInfosUser()
    await this.allVirementsClient()
  }

  async getInfosUser() {
    await this.clientService.getUserInfos().then((res: any) => {
      // console.log("getInfosUser : ")
      // console.log(res)
      if (res) {
        this.client = res;
      }
    });
  }

  async allVirementsClient() {
    await this.clientService.accountsClient(this.client._id)
      .then((res: any) => {
        // console.log("resssssssss : ")
        // console.log(res)
        if (res) {
          this.listVirements = res
          //location.href = '/sacs';
          //this.sacsList = res;
        }
      });
  }
  
  async showVirement(virement, index) {
    window.scrollTo(0, 0);
    // console.log("this.contentPage : ")
    // console.log(this.contentPage)
    // const element = this.contentPage.nativeElement.scrollTop = 0//.querySelector('app-menu').querySelector('div').querySelector('div')
    // console.log("elementttttttt : ")
    // console.log(element)
    // element.scrollTop = 0;
    // element.scrollTo(0, 0);
    // element.scrollIntoView();
    // console.log("amineeeeeeeeee")
    this.selectedVirement = index
    const confirmDialog = this.dialog.open(DetaisVirementComponent, {
      data: {
        virement: virement,
      }
    });

    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        // this.selectedVirement = -1
        // var data = confirmDialog.componentInstance.data
        // console.log("dataaaaaaaaaa : ")
        // console.log(data)
        // console.log("idSac : ")
        // console.log(idSac)
        // this.clientsService.affecteCollecteur(idClient, data.collector).then((res: any) => {
        //   // console.log("pickUpCollector : ");
        //   // console.log(res);
        //   if (res && !res.response) {
        //     alert("L'affectation est success")
        //     this.listClients()
        //   }
        //   // else {
        //   //   // console.log("erreur");
        //   //   this.notif.notify('error', "Une erreur s'est produite");
        //   // }
        // });
        // this.listSacs();
      }
    });

  }

}
