import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ClientsService } from '../clients/clients.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  idProvider: string

  constructor(private http: HttpClient,
    private router: Router,
    private userService: ClientsService) {
    this.idProvider = this.userService.getIdUser()
  }

  savePallet(data) {
    data.provider = this.idProvider
    return new Promise((slv) => {
      this.http.post(environment.apiSavePallet, data).subscribe(
        (data: any) => {
          slv(data);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(null);
        }
      );
    });
  }
  allPallets() {
    return new Promise((slv) => {
      this.http.post(environment.apiAllPallets, { }).subscribe(
        (data: any) => {
          if (data) {
            slv(data);
          } else slv(null);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(null);
        }
      );
    });
  }
  deletePallet(idPallet) {
    return new Promise((slv) => {
      this.http.post(environment.apiDeletePallet, {
        idPallet
      }).subscribe(
        (data: any) => {
          slv(data);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(null);
        }
      );
    });
  }


  allBoxesProducts(data: any) {
    data.idProvider = this.idProvider
    return new Promise((slv) => {
      this.http.post(environment.apiAllBoxesProducts, data).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(null);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(null);
        }
      );
    });
  }
  deleteBoxProduct(idBox) {
    return new Promise((slv) => {
      this.http.post(environment.apiDeleteBoxProduct, {
        idBox
      }).subscribe(
        (data: any) => {
          slv(data);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(null);
        }
      );
    });
  }
  saveBoxProduct(data) {
    data.provider = this.idProvider
    return new Promise((slv) => {
      this.http.post(environment.apiSaveBoxProduct, data).subscribe(
        (data: any) => {
          slv(data);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(null);
        }
      );
    });
  }
}
