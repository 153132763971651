<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-next-2 icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>{{'data.pickups' | translate }}
                <div class="page-title-subheading">{{'data.add_multiple_pickups_via_excel' | translate }}
                </div>
            </div>
        </div>
    </div>
</div>

<div class="main-card mb-3 card">
    <div class="card-header-tab card-header">
        <div class="card-header-title">
            <i class="header-icon lnr-rocket icon-gradient bg-tempting-azure"> </i>
            {{'data.sender' | translate }}
        </div>
        <!-- <div class="btn-actions-pane-right">
            <input hidden type="file" (change)="onFileChange($event)" multiple="false" #file>
            <button type="button" (click)="file.click()" data-toggle="tooltip" title="Example Tooltip" data-placement="bottom"
                class="btn-shadow mr-3 btn btn-info">
                <i class="fa fa-star"> Exporter mes commandes</i>
            </button>
        </div> -->
    </div>
    <div class="card-body">
        <!-- <h5 class="card-title">L'expéditeur</h5> -->
        <!-- <form class="" action="" method="post" onsubmit="return false;"
            (onsubmit)="addCommande(nameDest, adressDest, numTelDest);"> -->
        <div class="position-relative row form-group">
            <div class="col-sm-1"></div>
            <div class="col-sm-5">
                <!-- <legend for="client" class="space-bottom">L'expéditeur</legend> -->
                <input name="nameCompany" id="nameCompany" type="text" disabled class="form-control"
                    [value]="client?.prenom_client">
            </div>

            <div class="col-sm-5">
                <input name="nameExp" id="nameExp" type="text" disabled class="form-control"
                    [value]="client?.nom_client">
            </div>
        </div>

        <!-- <div class="position-relative row form-group">
                            <legend for="client" class="space-bottom">Client destinataire</legend>

                            <div class="col-sm-4">
                                <input name="nameDest" id="nameDest" type="text" class="form-control"
                                    placeholder="Nom de destinataire" #nameDest ngModel>
                                <mat-error style="color: red;font-weight: bold;" *ngIf="nameDest.value==''">
                                    Ajoutez le nom du client
                                </mat-error>
                            </div>

                            <div class="col-sm-4">
                                <input name="adressDest" id="adressDest" type="text" class="form-control"
                                    placeholder="Adresse du client" #adressDest ngModel>
                                <mat-error style="color: red;font-weight: bold;" *ngIf="adressDest.value==''">
                                    Ajoutez l'adresse du client
                                </mat-error>
                            </div>

                            <div class="col-sm-4">
                                <input name="numTelDest" id="numTelDest" type="number" class="form-control"
                                    placeholder="Numéro de téléphone" #numTelDest ngModel>
                                <mat-error style="color: red;font-weight: bold;"
                                    *ngIf="numTelDest.value=='' || numTelDest.value.length!=8">
                                    Ajoutez le numéro de téléphone du client
                                </mat-error>
                            </div>
                        </div>

                        <div class="position-relative row form-group">
                            <legend for="destination" class="space-bottom">Commande </legend>

                            <div class="col-sm-6">
                                <select class="form-control" (change)="selectVille($event)">
                                    <option [value]="ville" *ngFor="let ville of villes">
                                        {{ville}}</option>
                                </select>
                                <mat-error style="color: red;font-weight: bold;"
                                    *ngIf="selectedVille=='' || selectedVille=='Selectionnez une ville'">
                                    Ajoutez le numéro de téléphone du client
                                </mat-error>
                            </div>

                            <div class="col-sm-6">
                                <input name="totalCmd" id="totalCmd" type="number" class="form-control" disabled
                                    [(ngModel)]="totalCmd">
                            </div>
                        </div>

                        <div class="position-relative row form-group">
                            <legend for="client">Produits</legend>
                        </div>
                        <div [formGroup]="form">
                            <div class="position-relative row form-group"
                                *ngFor="let productGroup of form.get('products')['controls'];let i = index"
                                [formGroup]="productGroup">

                                <div class="col-sm-5">
                                    <input type="text" class="form-control" placeholder="Nom du produit"
                                        formControlName="nom_prod">
                                </div>

                                <div class="col-sm-3">
                                    <input type="number" class="form-control" placeholder="Quantité"
                                        formControlName="quantite" (input)="setTotalCmd()">
                                </div>

                                <div class="col-sm-3">
                                    <input type="number" class="form-control" placeholder="Prix unitaire"
                                        formControlName="prix_unitaire" (input)="setTotalCmd()">
                                </div>

                                <div class="col-sm-1">
                                    <button type="button" (click)="deleteProductGroup(i)"
                                        class="mr-2 btn-icon btn-icon-only btn btn-danger"><i
                                            class="pe-7s-trash btn-icon-wrapper"> </i></button>
                                </div>

                            </div>
                        </div>
                        <div class="row">
                            <mat-error style="color: red;font-weight: bold;"
                                *ngIf="form.value.products.length == 0 || (form.value.products.length > 0 && !productsNotEmpty())">
                                Les produits sont invalid
                            </mat-error><br>
                        </div>
                        <div class="position-relative row form-group">
                            <button type="button" (click)="addNewProductGroup()" class="mt-1 btn btn-warning"><i
                                    class="pe-7s-plus"></i> Ajouter un produit</button>
                        </div> -->

        <!-- <div class="position-relative row form-check">
                <div class="col-sm-10 offset-sm-10">
                    <button type="submit" (click)="addCommande(nameDest, adressDest, numTelDest)"
                        class="btn btn-primary">Ajouter
                        commandes</button>
                </div>
            </div> -->
        <!-- </form> -->

    </div>
</div>

<div class="main-card mb-12 card">
    <div class="card-header-tab card-header">
        <div class="card-header-title">
            <i class="header-icon lnr-rocket icon-gradient bg-tempting-azure"> </i>
            {{'data.my_pickups' | translate }}
        </div>
        <div class="btn-actions-pane-right">
            <input hidden type="file" (change)="readExcel($event)" multiple="false" #file>
            <button type="button" (click)="file.click()" data-toggle="tooltip" title="Example Tooltip"
                data-placement="bottom" class="btn-shadow mr-3 btn btn-info">
                <i class="fa fa-star"> {{'data.export_my_pickups' | translate }}</i>
            </button>
        </div>
    </div>
    <div class="card-body">

        <form class="" action="" method="post" onsubmit="return false;" (onsubmit)="addCommandes();">

            <ul class="list-group">
                <li class="list-group-item" *ngFor="let cmd of cmds, let index = index">
                    <div class="main-card mb-12 card">
                        <div class="card-header-tab card-header">

                            <div class="card-header-title">
                                <i class="header-icon lnr-rocket icon-gradient bg-tempting-azure"> </i>
                                Enlèvement {{index+1}}
                            </div>
                            <div class="btn-actions-pane-right">
                                <button type="button" (click)="deleteCmd(index)"
                                    class="btn-shadow mr-3 btn btn-danger">
                                    <i class="fa fa-times"></i>
                                </button>
                            </div>

                        </div>
                        <div class="card-body">
                            <div class="position-relative row form-group">
                                <!-- <div class="row"> -->
                                <div class="col-sm-3">
                                    <legend for="client" class="space-bottom">Nom de destinataire
                                    </legend>
                                    <input name="nameDest" id="nameDest" type="text" disabled
                                        class="form-control" value="{{cmd?.nameDest}}">
                                    <mat-error style="color: red;font-weight: bold;"
                                        *ngIf="!cmd.hasOwnProperty('nameDest') || cmd?.nameDest==''">
                                        Ajoutez le nom du client
                                    </mat-error>
                                </div>

                                <div class="col-sm-3">
                                    <legend for="client" class="space-bottom">Adresse du client</legend>
                                    <input name="adressDest" id="adressDest" type="text" disabled
                                        value="{{cmd.adressDest}}" class="form-control">
                                    <mat-error style="color: red;font-weight: bold;"
                                        *ngIf="!cmd.hasOwnProperty('adressDest') || cmd.adressDest==''">
                                        Ajoutez l'adresse du client
                                    </mat-error>
                                </div>

                                <div class="col-sm-3">
                                    <legend for="client" class="space-bottom">Ville</legend>
                                    <input name="destination" id="destination" type="text" disabled
                                        class="form-control" value="{{cmd.destination}}">
                                    <mat-error style="color: red;font-weight: bold;"
                                        *ngIf="!cmd.hasOwnProperty('destination') || cmd.destination==''">
                                        Ajoutez la destination
                                    </mat-error>
                                </div>

                                <div class="col-sm-3">
                                    <legend for="client" class="space-bottom">Numéro de téléphone
                                    </legend>
                                    <input name="numTelDest" id="numTelDest" type="number" disabled
                                        class="form-control" value="{{cmd.numTelDest}}">
                                    <mat-error style="color: red;font-weight: bold;"
                                        *ngIf="!cmd.hasOwnProperty('numTelDest') || cmd.numTelDest==''">
                                        Ajoutez le numéro de téléphone
                                    </mat-error>
                                </div>
                                <!-- </div> -->
                                <!-- <div class="row"> -->
                                <div class="col-sm-3">
                                    <legend for="client" class="space-bottom">Nom du produit</legend>
                                    <input name="nom_prod" id="nom_prod" type="text" disabled
                                        class="form-control" value="{{cmd.nom_prod}}">
                                    <mat-error style="color: red;font-weight: bold;"
                                        *ngIf="!cmd.hasOwnProperty('nom_prod') || cmd.nom_prod==''">
                                        Ajoutez le nom du produit
                                    </mat-error>
                                </div>

                                <div class="col-sm-3">
                                    <legend for="client" class="space-bottom">Quantité</legend>
                                    <input name="quantite" id="quantite" type="number"
                                        class="form-control" value="{{cmd.quantite}}" disabled>
                                    <mat-error style="color: red;font-weight: bold;"
                                        *ngIf="!cmd.hasOwnProperty('quantite') || cmd.quantite==''">
                                        Ajoutez la quantité du produit
                                    </mat-error>
                                </div>

                                <div class="col-sm-3">
                                    <legend for="client" class="space-bottom">Prix unitaire</legend>
                                    <input name="prix_unitaire" id="prix_unitaire" type="number" disabled
                                        class="form-control" value="{{cmd.prix_unitaire}}">
                                    <mat-error style="color: red;font-weight: bold;"
                                        *ngIf="!cmd.hasOwnProperty('prix_unitaire') || cmd.prix_unitaire==''">
                                        Ajoutez le prix unitaire
                                    </mat-error>
                                </div>

                                <div class="col-sm-3">
                                    <legend for="client" class="space-bottom">Prix total</legend>
                                    <input name="prix_total" id="prix_total" type="number" disabled
                                        class="form-control" value="{{cmd.prix_unitaire*cmd.quantite}}">
                                </div>
                                <!-- </div> -->
                            </div>
                        </div>
                    </div>
                </li>
            </ul>

            <div class="position-relative row form-group" *ngIf="cmds.length > 0 && cmdsIsValidated()">
                <div class="col-sm-10 offset-sm-10">
                    <button type="submit" (click)="addCommandes()" class="btn btn-primary">Ajouter
                        enlèvements</button>
                </div>
            </div>
        </form>

    </div>
</div>