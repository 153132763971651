<div id="body">
    <header class="clearfix">
        <div id="logo">
            <img src="../../../../assets/images/logo-facture.png">
        </div>
        <div id="company">
            <h2 class="name">Bon de Livraison N° {{cmd?.ref_facture_cmd}}</h2>
            <div><b>Date : </b>{{cmd?.date_creation_cmd | date:'d MMM y'}}</div>
            <div><b>Devise : TND</b></div>
        </div>

    </header>
    <main>
        <div id="details" class="clearfix">
            <div id="client">
                <h2 class="name">{{cmd.client_cmd?.company_name}}</h2>
                <table style="margin-bottom: 5px;">
                    <tbody>
                        <tr>
                            <td class="header header-bold">Nom de<br>l'expéditeur: </td>
                            <td class="header">{{cmd.client_cmd?.prenom_client}} {{cmd.client_cmd?.nom_client}}</td>
                        </tr>
                        <tr>
                            <td class="header header-bold">Adresse: </td>
                            <td class="header">{{cmd.client_cmd?.adresse_client}}</td>
                        </tr>
                        <tr>
                            <td class="header header-bold">Tél: </td>
                            <td class="header">{{cmd.client_cmd?.numTel_client}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div id="invoice">
                <qrcode print [width]="150" usesvg="true" [elementType]="'svg'" [qrdata]="cmd.qr_code"
                    [errorCorrectionLevel]="'M'">
                </qrcode>
                <!-- <table style="margin-bottom: 2px;">
                    <tbody>
                        <tr>
                            <td class="header header-bold"><b>Nom du client: </b></td>
                            <td class="header">{{cmd.user_destination?.nom_destination}}</td>
                        </tr>
                        <tr>
                            <td class="header header-bold">Adresse : </td>
                            <td class="header">{{cmd.user_destination?.adresse_destination}}
                                {{cmd.destination_cmd}}</td>
                        </tr>
                        <tr>
                            <td class="header header-bold">Téléphone : </td>
                            <td class="header">{{cmd.user_destination?.numtel_destination}}</td>
                        </tr>
                    </tbody>
                </table> -->
            </div>
        </div>
        <div id="invoice" style="margin-bottom: 20px;">
        <table style="margin-bottom: 2px;">
            <tbody>
                <tr>
                    <td class="header header-bold"><b>Nom du client: </b></td>
                    <td class="header"><b>{{cmd.user_destination?.nom_destination}}</b></td>
                </tr>
                <tr>
                    <td class="header header-bold">Adresse : </td>
                    <td class="header"><b>{{cmd.user_destination?.adresse_destination}}
                        {{cmd.destination_cmd}}</b></td>
                </tr>
                <tr>
                    <td class="header header-bold">Téléphone : </td>
                    <td class="header"><b>{{cmd.user_destination?.numtel_destination}}</b></td>
                </tr>
            </tbody>
        </table>
        </div>
        <table border="0" cellspacing="0" cellpadding="0">
            <thead>
                <tr style="border: 1px solid;">
                    <th class="title-table"></th>
                    <th class="title-table">Nom du produit</th>
                    <th class="title-table">Quantite</th>
                    <th class="title-table">Prix unitaire</th>
                    <th class="title-table">Montant Total HT</th>
                    <!-- <th class="title-table">Frais <br>de livraison</th>
                    <th class="title-table">Frais<br>livreur</th> -->
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let prd of cmd.products, let index = index">
                    <td class="ref">{{index+1}}</td>
                    <td class="desc">
                        {{prd.nom_prod}}<br>
                        <b *ngIf="cmd.isExchange==true">Echange ({{prd.quantite}} article(s))</b>
                    </td>
                    <td class="unit">{{prd.quantite}}</td>
                    <td class="ref">{{prd.prix_unitaire*((100-19)/100) |
                        number:'0.3'}} TND</td>
                    <td class="qty"><b>{{(prd.prix_unitaire*((100-19)/100))*prd.quantite |
                        number:'0.3'}} TND</b></td>
                    <!-- <td class="total">{{cmd?.client_cmd?.fraisDelivery?.frais_interne |
                        number:'0.3'}} DT</td>
                    <td class="total">{{userTracking.frais_livraison}} DT</td> -->
                </tr>


                <tr>
                    <td colspan="6" style="border: none; height: 30px;"></td>
                </tr>
                <tr>
                    <td colspan="6" style="border: none; height: 20px;"></td>
                </tr>
                <tr class="tfooter">
                    <td colspan="2" style="border: none;"></td>
                    <td colspan="2" class="center-td" style="font-weight: bold;">Montant HT</td>
                    <td><b>{{((cmd.total_ca_cmd+cmd.frais_livraison)*(100-19)/100) |
                        number:'0.3'}} TND</b></td>
                </tr>
                <tr class="tfooter">
                    <td colspan="2" style="border: none;"></td>
                    <td colspan="2" class="center-td" style="font-weight: bold;">TVA 19%</td>
                    <td><b>{{((cmd.total_ca_cmd+cmd.frais_livraison)*19/100) |
                        number:'0.3'}} TND</b></td>
                </tr>
                <tr class="tfooter">
                    <td colspan="2" style="border: none;"></td>
                    <td colspan="2" class="center-td" style="font-weight: bold;">Montant TTC</td>
                    <td><b>{{cmd.total_ca_cmd+cmd.frais_livraison |
                        number:'0.3'}} TND</b></td>
                </tr>
            </tbody>
        </table>

        <!-- <table border="0" cellspacing="0" cellpadding="0">
            <tbody>
                <tr>
                    <td class="signature">Signature de livreur</td>
                    <td class="signature">Service financier</td>
                </tr>
            </tbody>
        </table> -->
    </main>
</div>