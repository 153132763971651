import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AddCommandeComponent } from './pages/commandes/add-commande/add-commande.component';
// import { AddCommandeMbComponent } from './pages/commandes/add-commande-mb/add-commande-mb.component';
import { DashboardComponent } from './pages/dashboard/dashboard/dashboard.component';
import { CommandesComponent } from './pages/commandes/commandes/commandes.component';
import { DetailCommandeComponent } from './pages/commandes/detail-commande/detail-commande.component';
import { FactureCommandeComponent } from './pages/commandes/facture-commande/facture-commande.component';
import { StatusCommandesComponent } from './pages/commandes/status-commandes/status-commandes.component';
import { RechercheComponent } from './pages/dashboard/recherche/recherche.component';
import { DetailVirementsComponent } from './pages/statistics/detail-virements/detail-virements.component';
import { AuthGuard } from './guard/auth.guard';
import { ReturnedCommandesComponent } from './pages/commandes/returned-commandes/returned-commandes.component';
import { VirementsClientComponent } from './pages/statistics/virements-client/virements-client.component';
import { StatisticsCommandesComponent } from './pages/statistics/statistics-commandes/statistics-commandes.component';
import { MultipleCommandesComponent } from './pages/commandes/multiple-commandes/multiple-commandes.component';
import { ExchangeCommandeComponent } from './pages/commandes/exchange-commande/exchange-commande.component';
import { BaseLayoutComponent } from './layout/base-layout/base-layout.component';
import { AllProductsComponent } from './pages/settings/products/all-products/all-products.component';
import { AllPalletsComponent } from './pages/settings/pallets/all-pallets/all-pallets.component';
import { EditCommandeComponent } from './pages/commandes/edit-commande/edit-commande.component';

const routes: Routes = [
  {
    path: '',
    component: BaseLayoutComponent, canActivate: [AuthGuard],
    children: [

      // Dashboads
      { path: '', component: DashboardComponent, data: { extraParameter: 'dashboardsMenu' } },
      { path: 'search', component: RechercheComponent, data: { extraParameter: 'componentsMenu' } },
    
      { path: 'detail-virements', component: DetailVirementsComponent, data: { extraParameter: 'componentsMenu' } },
      { path: 'virements-client', component: VirementsClientComponent, data: { extraParameter: 'componentsMenu' } },
      { path: 'statistics', component: StatisticsCommandesComponent, data: { extraParameter: 'componentsMenu' } },
    
      { path: 'add-commande', component: AddCommandeComponent, data: { extraParameter: 'componentsMenu' } },
      { path: 'edit-commande', component: EditCommandeComponent, data: { extraParameter: 'componentsMenu' } },
      { path: 'multiple-commandes', component: MultipleCommandesComponent, data: { extraParameter: 'componentsMenu' } },
      { path: 'status-commandes', component: StatusCommandesComponent, data: { extraParameter: 'componentsMenu' } },
      { path: 'returned-commandes', component: ReturnedCommandesComponent, data: { extraParameter: 'componentsMenu' } },
    
      { path: 'commandes', component: CommandesComponent, data: { extraParameter: 'componentsMenu' } },
      { path: 'detail-cmd', component: DetailCommandeComponent, data: { extraParameter: 'componentsMenu' }},
      
      // SETTINGS
      { path: 'all-products', component: AllProductsComponent, data: { extraParameter: 'componentsMenu' } },
      { path: 'all-pallets', component: AllPalletsComponent, data: { extraParameter: 'componentsMenu' } },

      { path: 'exchange-cmd', component: ExchangeCommandeComponent, data: { extraParameter: 'componentsMenu' }},
    ]

  },

  { path: '', redirectTo: '', pathMatch: 'full' },

  { path: 'login', component: LoginComponent },

  { path: 'facture', component: FactureCommandeComponent, canActivate: [AuthGuard]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
