import { Component, OnInit } from '@angular/core';
import { ClientsService } from 'src/app/services/clients/clients.service';
import { CommandesService } from 'src/app/services/commandes/commandes.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-detail-virements',
  templateUrl: './detail-virements.component.html',
  styleUrls: ['./detail-virements.component.css']
})
export class DetailVirementsComponent implements OnInit {

  frais: any = {}

  statusCmds: Array<string> = []
  listCmdsLivred: any = []
  listCmdsReturned: any = []
  //////
  listCmdsLivredReturned: any = []

  totalCmdsLivred: any = 0
  totalCmdsReturned: any = 0
  fraisLivred: any = 0
  fraisLivredExterned: any = 0
  fraisReturned: any = 0

  nbrCmdsExterne: any = 0
  nbrCmdsInterne: any = 0

  statusCmd: any = []
  hasDemande: Boolean = true

  constructor(private clientsService: ClientsService,
    private translate: TranslateService,
    private cmdsService: CommandesService) { }

  async ngOnInit() {
    this.cmdsService.getLabeledStatus().then(
      (lst) => {
        this.statusCmd = lst
      }
    )

    this.hasDemandeClient()
    this.getFraisClient()
    await this.detailsCmdsNotPaiedAndNotReturned()
  }

  async detailsCmdsNotPaiedAndNotReturned() {
    let idClient = await this.clientsService.getIdUser()
    this.clientsService.allCmdsNotPaiedNotReturned(idClient).then(async (res: any) => {
      // console.log("res Not P and Retu")
      // console.log(res)
      if (res) {
        this.listCmdsLivred = res.cmdsNotPaied
        this.listCmdsReturned = res.cmdsNotReturned
        this.listCmdsLivredReturned = this.listCmdsLivred.concat(this.listCmdsReturned)
        this.totalCmdsLivred = await this.totalCmdsByStatus(this.listCmdsLivred)

        this.nbrCmdsInterne = await this.listCmdsLivred.filter(cmd => (cmd.destination_cmd == "Tunis"
          || cmd.destination_cmd == "Ariana"
          || cmd.destination_cmd == "Ben Arous" || cmd.destination_cmd == "Manouba")).length;
        this.nbrCmdsExterne = await this.listCmdsLivred.filter(cmd => !(cmd.destination_cmd == "Tunis"
          || cmd.destination_cmd == "Ariana"
          || cmd.destination_cmd == "Ben Arous" || cmd.destination_cmd == "Manouba")).length;

        this.fraisLivred = this.nbrCmdsInterne * this.frais.frais_interne
        this.fraisLivredExterned = this.nbrCmdsExterne * this.frais.frais_externe
        this.fraisReturned = this.listCmdsReturned.length * this.frais.frais_retour
      }
    });
    // this.totalCmdsLivred = await this.totalCmdsByStatus(this.listCmdsLivred)
    // this.totalCmdsReturned = await this.totalCmdsByStatus(this.listCmdsReturned)

  }

  // calculFrais() {
  //   this.listCmdsLivred.forEach(cmd => {
  //     if (cmd.destination_cmd == "Ariana" 
  //     || cmd.destination_cmd == "Ben Arous" || cmd.destination_cmd == "Manouba") {
  //       this.fraisLivred += this.frais.frais_interne
  //     }
  //   });
  // }

  // async listCmdsByStatus(status) {
  //   let userId = await this.clientsService.getIdUser()
  //   return await this.cmdsService.allCmdsClientByStatus(null, userId, status)
  //   // if (this.listCmds)
  //   // console.log(this.listCmds)
  //   //   return this.listCmds
  //   // else
  //   //   return 0
  // }

  async totalCmdsByStatus(array) {
    let total = 0
    array.forEach(element => {
      total += element.total_ca_cmd + element.frais_livraison
    });

    return total
  }

  async getFraisClient() {
    let userId = await this.clientsService.getIdUser()
    await this.clientsService.getFraisClient(userId)
      .then((res: any) => {
        // console.log("resssssssss :")
        // console.log(res)
        if (res) {
          this.frais = res
          //location.href = '/clients';
          //this.sacsList = res;
        }
      });
  }

  async demandePaiement() {
    this.translate.get('data.are_you_sure').subscribe(async (text: string) => {
      if (confirm(text)) {
        let userId = await this.clientsService.getIdUser()
        let accountExp = this.totalCmdsLivred - (this.fraisLivred + this.fraisLivredExterned + this.fraisReturned)
        let accoutLogistic = this.fraisLivred + this.fraisLivredExterned + this.fraisReturned
        let cmds = this.listCmdsLivredReturned.map(cmd => { return cmd._id; });
        // console.log("cmdsssss : ")
        // console.log(cmds)
        this.clientsService.addAccountClient(userId,
          this.totalCmdsLivred, accountExp, accoutLogistic, cmds).then(async (res: any) => {
            // console.log(res)
            if (res) {
              await this.detailsCmdsNotPaiedAndNotReturned()
            }
          });
      }
    });
  }

  async hasDemandeClient() {
    let userId = await this.clientsService.getIdUser()
    this.clientsService.hasDemandeClient(userId).then(async (res: any) => {
      // console.log(res)
      this.hasDemande = res
    });
  }
}
