import { Component, OnInit } from '@angular/core';
import { ClientsService } from 'src/app/services/clients/clients.service';
import { CommandesService } from 'src/app/services/commandes/commandes.service';

@Component({
  selector: 'app-statistics-commandes',
  templateUrl: './statistics-commandes.component.html',
  styleUrls: ['./statistics-commandes.component.css']
})
export class StatisticsCommandesComponent implements OnInit {

  title = 'angular-export-to-excel';

  villes = ["Selectionnez une ville",
    'Ariana',
    // 'Béja',
    'Ben Arous',
    'Bizerte',
    // 'Gabès',
    // 'Gafsa',
    // 'Jendouba',
    // 'Kairouan',
    // 'Kasserine',
    // 'Kébili',
    'Kef',
    // 'Mahdia',
    'Manouba',
    // 'Médenine',
    'Monastir',
    // 'Nabeul',
    'Sfax',
    // 'Sidi Bouzid',
    // 'Siliana',
    'Sousse',
    // 'Tataouine',
    'Tozeur',
    'Tunis',
    // 'Zaghouan'
  ];
  selectedVille: string = '';
  statsCmds: any = {}
  cmdList: any = [];
  statusCmds: any = []
  etatCmd: string = ""

  allSocieties = []

  constructor(private cmdsService: CommandesService,
    private clientService: ClientsService) { }

  ngOnInit(): void {
    this.cmdsService.getLabeledStatus().then(
      (lst) => {
        this.statusCmds = lst
      }
    )
    
    this._fetchAllSocieties()
  }

  async _fetchAllSocieties() {
    await this.cmdsService.allSocieties().then((res: any) => {
      // console.log("resssssssss services : ")
      // console.log(res)
      if (res) {
        this.allSocieties = res;
      }
    });
  }

  async inventaire(datein, datefin) {
    let userId = await this.clientService.getIdUser()
    await this.cmdsService.statisticsCmds(userId, datein.value, datefin.value, this.selectedVille).then((res: any) => {
      // console.log("res statisticsCmds : ")
      // console.log(res)
      if (res) {
        this.statsCmds = res;
      }
    });
  }

  selectChangeVille(event: any) {
    if (event.target.value == "Selectionnez une ville") {
      this.selectedVille = "";
    } else {
      this.selectedVille = event.target.value;
    }
  }

  displayCmdsByStatus(status) {
    if (status == "validated") {
      this.etatCmd = "validé"
      this.cmdList = this.statsCmds.allCmdsValidated
    } else if (status == "transporting_depot") {
      this.etatCmd = "préparé"
      this.cmdList = this.statsCmds.allCmdsDepot
    } else if (status == "transporting") {
      this.etatCmd = "transporté"
      this.cmdList = this.statsCmds.allCmdsTransporting
    } else if (status == "delivered") {
      this.etatCmd = "livré"
      this.cmdList = this.statsCmds.allCmdsLivred
    } else if (status == "paied") {
      this.etatCmd = "payé"
      this.cmdList = this.statsCmds.allCmdsPaied
    } else if (status == "returned") {
      this.etatCmd = "retournée"
      this.cmdList = this.statsCmds.allCmdsReturned
    } else if (status == "canceled") {
      this.etatCmd = "annulée"
      this.cmdList = this.statsCmds.allCmdsCanceled
    } else {
      this.etatCmd = ""
      this.cmdList = []
    }
  }

  exportToExcel(datein, datefin) {

    // this.cmdList.forEach((row: any) => {
    //   this.dataForExcel.push(Object.values(row))
    // })

    let reportData = {
      title: 'Statistique des commandes',
      data: this.cmdList,
      headers: ["#", "Référence", "Date Création", "Nom Client & Téléphone", "Adresse", "Ville", "Total Commande"], // Object.keys(this.cmdList[0])
      etatCmd: this.etatCmd, 
      startDate: datein.value, 
      endDate: datefin.value
    }

    this.cmdsService.exportExcel(reportData);
  }

}
