import { Component, OnInit } from '@angular/core';
import { ThemeOptions } from 'src/app/theme-options';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  constructor(public globals: ThemeOptions,) { }

  ngOnInit(): void {
  }

  closeToggleSidebarMobile() {
    this.globals.toggleSidebarMobile = false;
  }

}
