import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SettingsService } from 'src/app/services/settings/settings.service';
import { TranslateService } from '@ngx-translate/core';
import { CommandesService } from 'src/app/services/commandes/commandes.service';

@Component({
  selector: 'app-add-pallet',
  templateUrl: './add-pallet.component.html',
  styleUrls: ['./add-pallet.component.css']
})
export class AddPalletComponent implements OnInit {

  isSaved: boolean = true

  @Output() result = new EventEmitter<any>();
  @Input() pallet: any;

  constructor(private activeModal: NgbActiveModal,
    private translate: TranslateService,
    private cmdService: CommandesService,
    private service: SettingsService,) { }

  ngOnInit(): void {
  }

  savePallet() {
    // console.log(this.pallet)
    var isValidate = this.checkField()
    if (!isValidate) {
      this.isSaved = isValidate
      this.translate.get('data.please_fill_in_the_required_fields')
        .subscribe(async (text: string) => {
          alert(text)
        });
    } else {
      this.pallet.volume = this.cmdService.formatNumber(
        this.pallet.longueur * this.pallet.largeur * this.pallet.hauteur, 3)
      this.service.savePallet(this.pallet)
        .then((res: any) => {
          if (res) {
            // console.log("resssssssss : ")
            // console.log(res)
            if (res?.response) {
              this.result.emit(true);
              this.activeModal.dismiss();
            }
          }
        });
    }
  }

  checkField() {
    if (this.pallet.name != null && this.pallet.name != "" &&
      this.pallet.longueur != null && this.pallet.longueur.toString() != "" &&
      this.pallet.largeur != null && this.pallet.largeur.toString() != "" &&
      this.pallet.hauteur != null && this.pallet.hauteur.toString() != "")
      return true
    else
      return false
  }

  closeModal() {
    this.result.emit(null);
    this.activeModal.dismiss();
  }

}
