import { Component, ElementRef, OnInit } from '@angular/core';
import { ClientsService } from 'src/app/services/clients/clients.service';
import * as Excel from 'exceljs/dist/exceljs.min.js';
import { ViewChild } from '@angular/core';
import { CommandesService } from 'src/app/services/commandes/commandes.service';
import { variable } from '@angular/compiler/src/output/output_ast';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-multiple-commandes',
  templateUrl: './multiple-commandes.component.html',
  styleUrls: ['./multiple-commandes.component.css']
})
export class MultipleCommandesComponent implements OnInit {

  @ViewChild('file')
  myfileImport: ElementRef;

  client: any = null
  cmds: any = []

  constructor(private cmdService: CommandesService,
    private translate: TranslateService,
    private clientService: ClientsService) { }

  async ngOnInit() {
    await this.getInfosUser()
  }

  async getInfosUser() {
    await this.clientService.getUserInfos().then((res: any) => {
      // console.log("getUserInfos");
      // console.log(res);
      if (res) {
        this.client = res;
      }
    });
  }

  readExcel(event) {

    const workbook = new Excel.Workbook();
    const target: DataTransfer = <DataTransfer>(event.target);
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }

    /**
     * Final Solution For Importing the Excel FILE
     */
    const header = [] //["nameDest", "adressDest", "numTelDest", "destination", "nom_prod", "quantite", "prix_unitaire"]

    const arryBuffer = new Response(target.files[0]).arrayBuffer();
    arryBuffer.then((data) => {
      workbook.xlsx.load(data)
        .then(async () => {

          // play with workbook and worksheet now
          // console.log(workbook);
          const worksheet = workbook.getWorksheet(1);
          // console.log('rowCount: ', worksheet.rowCount);

          const promises = [];
          worksheet.eachRow((row, rowNumber) => {
            // console.log('Row: ' + rowNumber + ' Value: ' + row.values);
            // console.log('Row ' + rowNumber + ' = ' + JSON.stringify(row.values));
            let cmd = {}
            if (rowNumber == 1) {
              row.eachCell((cell, colNumber) => {
                // console.log('Cell ' + colNumber + ' , ' + header[colNumber - 1] + ' = ' + cell.value);
                header.push(cell.value.trim())
              });

            } else {

              // const promise = db.insert(row); // <-- whatever async operation you have here
              row.eachCell((cell, colNumber) => {
                // console.log('Cell ' + colNumber + ' , ' + header[colNumber - 1] + ' = ' + cell.value);
                cmd[header[colNumber - 1]] = cell.value
              });
              promises.push(cmd);
              // this.cmds.push(cmd)
              // return cmd
            }
          });
          this.cmds = await Promise.all(promises)
          // console.log("all cmds : ")
          // console.log(this.cmds)
        });
    });

    this.myfileImport.nativeElement.value = "";

  }

  deleteCmd(index) {
    this.cmds.splice(index, 1)
  }
  cmdsIsValidated() {
    for (let i = 0; i < this.cmds.length; i++) {
      if ((!this.cmds[i].hasOwnProperty('nameDest') || (this.cmds[i].hasOwnProperty('nameDest') && this.cmds[i].nameDest == ""))
        || (!this.cmds[i].hasOwnProperty('adressDest') || (this.cmds[i].hasOwnProperty('adressDest') && this.cmds[i].adressDest == ""))
        || (!this.cmds[i].hasOwnProperty('numTelDest') || (this.cmds[i].hasOwnProperty('numTelDest') && this.cmds[i].numTelDest == ""))
        || (!this.cmds[i].hasOwnProperty('destination') || (this.cmds[i].hasOwnProperty('destination') && this.cmds[i].destination == ""))
        || (!this.cmds[i].hasOwnProperty('nom_prod') || (this.cmds[i].hasOwnProperty('nom_prod') && this.cmds[i].nom_prod == ""))
        || (!this.cmds[i].hasOwnProperty('quantite') || (this.cmds[i].hasOwnProperty('quantite') && this.cmds[i].quantite == ""))
        || (!this.cmds[i].hasOwnProperty('prix_unitaire') || (this.cmds[i].hasOwnProperty('prix_unitaire') && this.cmds[i].prix_unitaire == ""))
      ) {
        // console.log("falseeee")
        return false
      }
    }
    return true
  }
  async addCommandes() {
    if (!this.cmdsIsValidated()) {
      this.translate.get('data.order_added_successfully')
        .subscribe(async (text: string) => {
          alert(text)
        });
      this.cmds = []
    } else {
      let userId = await this.clientService.getIdUser()
      let validated = true
      this.cmds.forEach(async (cmd) => {
        let products = [
          {
            "nom_prod": cmd.nom_prod,
            "quantite": cmd.quantite,
            "prix_unitaire": cmd.prix_unitaire
          }
        ]
        // await this.cmdService.addCmd(/*userId, cmd.nameDest, cmd.adressDest,
        //   cmd.numTelDest, cmd.numTelDest, cmd.quantite * cmd.prix_unitaire, products*/)
        //   .then((res: any) => {
        //     // console.log("resssssssss : ")
        //     // console.log(res)
        //     if (res) {
        //       //   alert('Commande est ajoutée avec succès');
        //       //   this.cleanData(nameDest, adressDest, numTelDest)
        //       //   //location.href = '/sacs';
        //       //   //this.sacsList = res;
        //       validated = validated && res
        //       // console.log("validated : " + validated)
        //     }
        //   });
      });

      if (validated) {
        this.translate.get('data.all_orders_added_successfully')
          .subscribe(async (text: string) => {
            alert(text)
          });
        this.cmds = []
      }
    }
  }

}
