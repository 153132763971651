<div [ngClass]="{
    'closed-sidebar' : globals.toggleSidebar,
    'sidebar-mobile-open' : globals.toggleSidebarMobile,
    'closed-sidebar-open': globals.sidebarHover || globals.toggleSidebarMobile,
    'header-menu-open' : globals.toggleHeaderMobile,
    'fixed-footer' : globals.toggleFixedFooter
    }" [class]="'app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header'">
    <app-top></app-top>
    <div class="app-main">
        <app-menu></app-menu>
        <div class="app-main__outer">
            <div class="app-main__inner">
                <div>
                    <router-outlet #o="outlet"></router-outlet>
                </div>
            </div>
            <!-- <app-footer></app-footer> -->
        </div>
    </div>
    <div class="sidebar-menu-overlay" (click)="toggleSidebarMobile()"></div>
</div>
<!-- <ngx-loading-bar [color]="'var(--primary)'"></ngx-loading-bar> -->