<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-box2 icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>Inventaire
                <div class="page-title-subheading">Faire un <strong>inventaire</strong> sur les ventes
                    et les en-cours.
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="card-header">Enlèvements
                <div class="btn-actions-pane-right">
                    <!-- <div role="group" class="btn-group-sm btn-group">
                        <div class="badge badge-warning">{{statusCmds}}</div>
                    </div> -->
                </div>
            </div>
            <div class="tab-content">
                <div class="tab-pane tabs-animation fade show active" id="tab-content-0"
                    role="tabpanel">
                    <div class="card-body">
                        <form class="" action="" method="post" onsubmit="return false;"
                            (onsubmit)="inventaire(datein, datefin);">
                            <div class="form-row">
                                <div class="col-md-4">
                                    <div class="position-relative form-group">
                                        <legend for="datein" class="">{{'data.start_date' | translate }}</legend>
                                        <input id="datein" type="date" class="form-control"
                                            name="datein" tabindex="1" #datein ngModel>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="position-relative form-group">
                                        <legend for="datefin" class="">{{'data.end_date' | translate }}</legend>
                                        <input id="datefin" type="date" class="form-control"
                                            name="datefin" tabindex="1" #datefin ngModel>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="position-relative form-group">
                                        <legend for="ville" class="">Société</legend>
                                        <!-- <select class="form-control selectric"
                                            (change)="selectChangeVille($event)">
                                            <option [value]="ville" *ngFor="let ville of villes">
                                                {{ville}}</option>
                                        </select> -->
                                        <select class="form-control" name="society" id="society">
                                            <option [value]="null">Sélectionnez la société</option>
                                            <option [value]="soc?._id" *ngFor="let soc of allSocieties">
                                                {{soc?.name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <button type="submit" (click)="inventaire(datein, datefin)"
                                    class="btn btn-primary btn-lg btn-icon icon-righ btn-search"
                                    id="search" tabindex="4">
                                    <i class="fas fa-search"></i>
                                </button>
                                <!-- <div class="col-md-1">
                                        <div class="position-relative form-group">
                                            <legend for="firstName" class="">dd</legend>
                                            <button class="mb-2 mr-2 btn btn-primary">
                                                <i class="nav-link-icon pe-7s-search"></i>
                                            </button>
                                        </div>
                                    </div> -->
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            
            <div class="d-block text-center card-footer">
                <div class="clearfix mb-3"></div>
                <div class="row">
                    <div class="col-md-2 etat-cmd">
                        <div class="card-stats-item" (click)="displayCmdsByStatus('validated')">
                            <div class="card-stats-item-count">
                                {{statsCmds.allCmdsValidated?.length}}</div>
                            <div class="card-stats-item-label">Validée</div>
                        </div>
                    </div>
                    <div class="col-md-2 etat-cmd">
                        <div class="card-stats-item" (click)="displayCmdsByStatus('transporting_depot')">
                            <div class="card-stats-item-count">{{statsCmds.allCmdsDepot?.length}}
                            </div>
                            <div class="card-stats-item-label">Au dépôt</div>
                        </div>
                    </div>
                    <div class="col-md-2 etat-cmd">
                        <div class="card-stats-item" (click)="displayCmdsByStatus('transporting')">
                            <div class="card-stats-item-count">
                                {{statsCmds.allCmdsTransporting?.length}}</div>
                            <div class="card-stats-item-label">En cours de transport</div>
                        </div>
                    </div>
                    <div class="col-md-2 etat-cmd">
                        <div class="card-stats-item" (click)="displayCmdsByStatus('delivered')">
                            <div class="card-stats-item-count">{{statsCmds.allCmdsLivred?.length}}
                            </div>
                            <div class="card-stats-item-label">Livré</div>
                        </div>
                    </div>
                    <div class="col-md-1 etat-cmd">
                        <div class="card-stats-item" (click)="displayCmdsByStatus('paied')">
                            <div class="card-stats-item-count">{{statsCmds.allCmdsPaied?.length}}
                            </div>
                            <div class="card-stats-item-label">Payé</div>
                        </div>
                    </div>
                    <div class="col-md-2 etat-cmd">
                        <div class="card-stats-item" (click)="displayCmdsByStatus('returned')">
                            <div class="card-stats-item-count">{{statsCmds.allCmdsReturned?.length}}
                            </div>
                            <div class="card-stats-item-label">Retournée</div>
                        </div>
                    </div>
                    <div class="col-md-1 etat-cmd">
                        <div class="card-stats-item" (click)="displayCmdsByStatus('canceled')">
                            <div class="card-stats-item-count">{{statsCmds.allCmdsCanceled?.length}}
                            </div>
                            <div class="card-stats-item-label">Annulée</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="clearfix mb-3"></div>

            <div class="row">
                <div class="col-md-6 col-xl-4">
                    <div class="widget-content">
                        <div class="widget-content-wrapper text-black">
                            <div class="widget-content-left mr-3">
                                <div class="icon-wrapper rounded-circle">
                                    <div class="icon-wrapper-bg bg-primary"></div>
                                    <i class="pe-7s-display1 text-back"></i>
                                </div>
                            </div>
                            <div class="widget-content-left">
                                <div class="widget-heading">Total d'enlèvements</div>
                            </div>
                            <div class="widget-content-right">
                                <div class="widget-numbers text-black"><span>{{statsCmds.allCmds?.length}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="col-md-6 col-xl-4"></div>

                <div class="col-md-6 col-xl-4">
                    <div class="widget-content">
                        <div class="widget-content-wrapper text-black">
                            <div class="widget-content-left mr-3">
                                <div class="icon-wrapper rounded-circle">
                                    <div class="icon-wrapper-bg bg-primary"></div>
                                    <i class="pe-7s-cash text-back"></i>
                                </div>
                            </div>
                            <div class="widget-content-left">
                                <div class="widget-heading">Total Prix <br>des enlèvements livré</div>
                            </div>
                            <div class="widget-content-right">
                                <div class="widget-numbers text-black"><span>{{statsCmds.totalLivredCmds}} EUR</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="card-header">Enlèvements
                <!-- <div class="btn-actions-pane-right">
                    <div role="group" class="btn-group-sm btn-group">
                        <button class="active btn btn-focus">Last Week</button>
                        <button class="btn btn-focus">All Month</button>
                    </div>
                </div> -->
            </div>
            <div class="card-body">
                <div class="tab-content">
                    <div class="table-responsive">
                        <div class="budget-price justify-content-center" *ngIf="cmdList?.length === 0">
                            <h2 class="text-center" style="color: red;">Aucun enlèvement créé</h2>
                        </div>
                        <table id="contactJs"
                            class="align-middle mb-0 table table-borderless table-striped table-hover"
                            *ngIf="cmdList?.length > 0">
                            <thead>
                                <tr>
                                    <th class="text-center">#</th>
                                    <th>Société</th>
                                    <th>Référence</th>
                                    <th>Date Création</th>
                                    <!-- <th class="text-center">Nom Client<br>&<br>Téléphone</th>
                                    <th class="text-center">Ville</th> -->
                                    <th class="text-center">Produits<br>
                                        Quantité - Nom produit</th>
                                    <!-- <th class="text-center">Total<br>Commande</th> -->
                                    <th class="text-center">{{'data.total_volume' | translate }} ({{'data.cubic_meters' | translate }})</th>
                                    <th class="text-center">{{'data.total_weight' | translate }} ({{'data.kg' | translate }})</th>
                                    <th class="text-center">Status</th>
                                    <!-- <th class="text-center">Actions</th> -->
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let cmd of cmdList, let index = index">
                                    <td class="text-center text-muted">#{{index+1}}</td>
                                    <td>
                                        <div class="widget-content p-0">
                                            <div class="widget-content-wrapper">
                                                <div class="widget-content-left mr-3">
                                                    <div class="widget-content-left">
                                                        <img width="40" class="rounded-circle"
                                                            [src]="cmd?.society?.logo" alt="Logo"
                                                            style="border: 1px solid #000;">
                                                    </div>
                                                </div>
                                                <div class="widget-content-left flex2">
                                                    <div class="widget-heading">
                                                        {{cmd?.society?.name}}
                                                    </div>
                                                    <div class="widget-subheading opacity-7">
                                                        {{cmd?.society?.pays}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="text">{{cmd.ref_facture_cmd}}</td>
                                    <td class="text-center">
                                        {{cmd.date_creation_cmd | date:'d MMM y'}}<br>
                                        <b>{{cmd.date_creation_cmd | date:'HH:mm'}}</b>
                                    </td>
                                    <!-- <td class="text-center">
                                        <div class="widget-content p-0">
                                            <div class="widget-content-wrapper">
                                                <div class="widget-content-left flex2">
                                                    <div class="widget-heading">
                                                        {{cmd.user_destination.nom_destination}}</div>
                                                    <div class="widget-subheading opacity-7">
                                                        {{cmd.user_destination.numtel_destination}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        {{cmd.user_destination.adresse_destination}}<br>
                                        <strong>{{cmd.destination_cmd}}</strong>
                                    </td> -->
                                    <td>
                                        <!-- <div *ngFor="let prd of cmd.products, let index = index">
                                            {{prd.quantite}} - {{prd.nom_prod}}
                                        </div> -->
                                        <ul class="rm-list-borders rm-list-borders-scroll list-group list-group-flush"
                                            *ngIf="cmd?.boxes?.length > 0">
                                            <li class="list-group-item"
                                                *ngFor="let box of cmd?.boxes, let index = index">
                                                <div class="widget-content p-0">
                                                    <div class="widget-content-wrapper">
                                                        <div class="widget-content-left mr-3">
                                                            <img width="40" height="40"
                                                                src="https://assets.marketbey.tn/WTM/products/box.png"
                                                                alt="">
                                                            <!-- <ngx-avatar class="rounded-circle" name="{{driver?.travelerUser?.prenom_user}} {{driver?.travelerUser?.nom_user}}">
                                                </ngx-avatar> -->
                                                        </div>
                                                        <div class="widget-content-left">
                                                            <div class="widget-heading">
                                                                {{box?.quantite}} - {{box?.nom_prod}}
                                                            </div>
                                                            <div class="widget-subheading">
                                                                {{box?.des_prod}}
                                                            </div>
                                                        </div>
                                                        <div class="widget-content-right">
                                                            <div class="font-size-lg text-muted">
                                                                <span>{{box?.quantite * box?.volume | number: '0.1'}}
                                                                    {{'data.cubic_meters' | translate }}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                        <ul class="rm-list-borders rm-list-borders-scroll list-group list-group-flush"
                                            *ngIf="cmd?.pallets?.length > 0">
                                            <li class="list-group-item"
                                                *ngFor="let pallet of cmd?.pallets, let index = index">
                                                <div class="widget-content p-0">
                                                    <div class="widget-content-wrapper">
                                                        <div class="widget-content-left mr-3">
                                                            <img width="40" height="40"
                                                                src="https://assets.marketbey.tn/WTM/products/pallet.png"
                                                                alt="">
                                                            <!-- <ngx-avatar class="rounded-circle" name="{{driver?.travelerUser?.prenom_user}} {{driver?.travelerUser?.nom_user}}">
                                                </ngx-avatar> -->
                                                        </div>
                                                        <div class="widget-content-left">
                                                            <div class="widget-heading">
                                                                {{pallet?.quantity}} - {{pallet?.name_pallet}}
                                                            </div>
                                                            <!-- <div class="widget-subheading">
                                                                {{pallet?.driver_id?.user_transportor?.tel_user}}
                                                            </div> -->
                                                        </div>
                                                        <div class="widget-content-right">
                                                            <div class="font-size-lg text-muted">
                                                                <span>{{pallet?.quantity * pallet?.volume | number:
                                                                    '0.1'}} {{'data.cubic_meters' | translate }}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </td>
                                    <!-- <td class="text-center">{{cmd.total_ca_cmd + cmd.frais_livraison | number:'0.3'}} EUR</td> -->
                                    <td class="text-center">
                                        {{ cmd?.totalPalletVolume | number:'0.1' }}
                                    </td>
                                    <td class="text-center">
                                        {{ cmd?.totalPoids | number:'0.1' }}
                                    </td>
                                    <td class="text-center">
                                        <div class="badge badge-{{statusCmds[cmd.etat_cmd].color}}">
                                            {{statusCmds[cmd.etat_cmd].label}}</div>
                                    </td>
                                    <!-- <td class="text-center">
                                        <button [routerLink]="['/detail-cmd']"
                                            [queryParams]="{ cmd: cmd._id }" type="button"
                                            id="PopoverCustomT-1"
                                            class="btn btn-primary btn-sm">Details</button>
                                        <button [routerLink]="['/facture']"
                                            [queryParams]="{ cmd: cmd._id }" type="button"
                                            id="PopoverCustomT-1"
                                            class="btn btn-primary btn-sm">Facture</button>
                                    </td> -->
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="d-block text-center card-footer"
                        *ngIf="cmdList?.length > 0">
                        <button class="mr-2 btn-icon btn-icon-only btn btn-outline-success" 
                        (click)="exportToExcel(datein, datefin)"><i
                                class="pe-7s-download btn-icon-wrapper"> Exporter statistique</i></button>
                        <!-- <button class="btn-wide btn btn-success">Exporter</button> -->
                    </div>
                    <!-- <div class="d-block text-center card-footer" *ngIf="cmdList?.length > 0 && pages?.length > 1">
                        <nav aria-label="Page navigation example">
                            <ul class="pagination justify-content-center">
                                <li [ngClass]="(currentPage===0)?'page-item disabled':'page-item'">
                                    <a class="page-link" tabindex="-1" (click)="previousPage(ref, numero, datein, datefin)">
                                        <i class="nav-link-icon pe-7s-angle-left-circle"></i> Précédent</a>
                                </li>

                                <div *ngFor="let p of pagesDisplaied">
                                    <li [ngClass]="(currentPage===p)?'page-item active':'page-item'">
                                        <a *ngIf="currentPage!==p" class="page-link" (click)="selectPage(p, ref, numero, datein, datefin)">{{p+1}}</a>

                                        <span *ngIf="currentPage===p" class="page-link">
                                            {{p+1}}
                                            <span class="sr-only">(current)</span>
                                        </span>
                                    </li>
                                </div>

                                <li [ngClass]="(currentPage===totalPages-1)?'page-item disabled':'page-item'">
                                    <a class="page-link" tabindex="-1" (click)="nextPage(ref, numero, datein, datefin)">Suivant <i class="nav-link-icon pe-7s-angle-right-circle"></i></a>
                                </li>
                            </ul>
                        </nav>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>