import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// import { Console } from 'console';
import { TranslateService } from '@ngx-translate/core';
import { ClientsService } from 'src/app/services/clients/clients.service';
import { CommandesService } from 'src/app/services/commandes/commandes.service';
import { SweetalertService } from 'src/app/services/sweetalert/sweetalert.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-status-commandes',
  templateUrl: './status-commandes.component.html',
  styleUrls: ['./status-commandes.component.css']
})
export class StatusCommandesComponent implements OnInit {

  isLoading: any = true;
  statusCmds: Array<string> = []
  listCmds: any = []

  currentPage: number = 0
  totalPages: number = 1
  pages: Array<number> = [];
  pagesDisplaied: Array<number> = [];

  statusCmd: any = []

  constructor(private route: ActivatedRoute,
    private cmdsService: CommandesService,
    private translate: TranslateService,
    private sweetalert: SweetalertService,
    private clientService: ClientsService) { }

  ngOnInit(): void {
    this.cmdsService.getLabeledStatus().then(
      (lst) => {
        this.statusCmd = lst
      }
    )

    this.route.queryParams.subscribe((params) => {
      this.statusCmds = params.status;
      this.listCmdsClient(this.statusCmds)
    });
  }

  goToNewTab(idCmd, route) {
    this.cmdsService.goToNewTab(idCmd, route)
  }

  // ['transporting', 'transported', 'arrived']
  async listCmdsClient(status) {
    let userId = await this.clientService.getIdUser()
    await this.cmdsService.allCmdsClientByStatus(this.currentPage, userId, [status]).then((res: any) => {
      // console.log("dataaaaaaaaaa : ");
      // console.log(res)
      if (res) {
        this.isLoading = false
        this.listCmds = res.items;
        this.totalPages = res.totalPages
        this.currentPage = res.currentPage
        this.pages = Array.from(Array(res.totalPages)).map((x, i) => i)
        this.splicePages()
      }
    });
  }

  splicePages() {
    // console.log("currentPage : ")
    //     console.log(this.currentPage)
    if (this.pages.length > 10) {
      // if(this.currentPage < this.pages.length-10) {
      //   this.pagesDisplaied = this.pages.splice(this.currentPage, 10);
      //   console.log("if 1 pagesDisplaied : ")
      //   console.log(this.pagesDisplaied)
      // } else 
      if (this.currentPage < 5) {
        this.pagesDisplaied = this.pages.splice(0, 10);
        // console.log("if 1 pagesDisplaied : ")
        // console.log(this.pagesDisplaied)
      } else if (this.currentPage >= 5 && this.currentPage < this.pages.length - 5) {
        this.pagesDisplaied = this.pages.splice(this.currentPage - 4, 10);
        // console.log("if 2 pagesDisplaied : ")
        // console.log(this.pagesDisplaied)
      } else {
        this.pagesDisplaied = this.pages.splice(this.pages.length - 10, 10);
        // console.log("else pagesDisplaied : ")
        // console.log(this.pagesDisplaied)
      }
    } else {
      this.pagesDisplaied = this.pages
    }
  }

  nextPage() {
    this.currentPage++
    this.listCmdsClient(this.statusCmds);
  }

  previousPage() {
    this.currentPage--
    this.listCmdsClient(this.statusCmds);
  }

  selectPage(page) {
    this.currentPage = page
    this.listCmdsClient(this.statusCmds);
  }

  async updateStatusCmd(cmd, status) {
    // this.notif.notify('success', 'Commande mis à jour avec succès');
    // return;
    this.translate.get('data.are_you_sure').subscribe(async (text: string) => {
      if (confirm(text))
        await this.cmdsService.updateCmdStatus(cmd, status).then((res) => {
          if (res) {
            this.translate.get('data.order_updated_successfully')
              .subscribe(async (text: string) => {
                alert(text)
              });
            // cmd.etat_cmd = "canceled"
            this.listCmdsClient(this.statusCmds)
          }
          else {
            this.translate.get('data.an_error_occurred')
              .subscribe(async (text: string) => {
                alert(text)
              });
          }
        });
    });
  }
  validateTakeOff(cmd) {
    if (cmd?.etat_cmd == "validated")
      this.sweetalert.confirmActionBtn("Vous pourrez confirmer l'Enlèvement de la marchandise par la société de transport !")
        // + this.cmd?.pickupedBy?.nameSociety + " !")
        .then((result: any) => {
          if (result.isConfirmed) {
            this.cmdsService.updateCmdStatus(cmd?._id, "take_off").then((res: any) => {
              if (res) {
                // console.log("successssssssss")

                this.sweetalert.alertSuccess("La commande a été enlevé avec succès.").then(async (result: any) => {
                  if (result.isConfirmed) {
                    this.listCmdsClient(this.statusCmds)
                  }
                })
              }
            });
            // this.sweetalert.alertConfirmed().then((res: any) => {
            //   // location.href = "/product/product-list"
            //   this.allMissionDrivers(this.filterDate)
            // })
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            this.sweetalert.alertCanceled().then((res: any) => {
              // location.href = "/product/product-list"
            })
          }
        })
  }
}
