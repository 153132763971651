<div class="modal-header">
    <h4 class="modal-title">{{'data.add_a_new_pallet' | translate }}</h4>
    <button type="button" aria-label="Close" (click)="closeModal()">
        <i class="fa fa-close"></i>
    </button>
</div>
<div class="modal-body">

    <div class="row">
        <div class="col-md-12">
            <div class="main-card mb-3 card">
                <div class="tab-content" id="tab-content">
                    <div class="main-card mb-12 card">
                        <div class="card-body">
                            <div class="row form-group">
                                <div class="col-12 col-md-12">
                                    <legend for="name" class="">{{'data.name_of_the_pallet' | translate }} *</legend>
                                    <input name="name" id="name" type="text" class="form-control"
                                        [(ngModel)]="pallet.name"
                                        [class]="(!isSaved && pallet.name=='') ? 'input-border-danger' : ''">
                                </div>

                                <div class="col-12 col-md-4">
                                    <legend for="longueur" class="">{{'data.length' | translate }} ({{'data.meters' |
                                        translate }}) *</legend>
                                    <input name="longueur" id="longueur" type="number" class="form-control"
                                        [(ngModel)]="pallet.longueur"
                                        [class]="(!isSaved && pallet?.longueur==null) ? 'input-border-danger' : ''">
                                </div>

                                <div class="col-12 col-md-4">
                                    <legend for="largeur" class="">{{'data.width' | translate }} ({{'data.meters' |
                                        translate }}) *</legend>
                                    <input name="largeur" id="largeur" type="number" class="form-control"
                                        [(ngModel)]="pallet.largeur"
                                        [class]="(!isSaved && pallet?.largeur==null) ? 'input-border-danger' : ''">
                                </div>

                                <div class="col-12 col-md-4">
                                    <legend for="hauteur" class="">{{'data.height' | translate }}
                                        ({{'data.meters' | translate }}) *</legend>
                                    <input name="hauteur" id="hauteur" type="number" class="form-control"
                                        [(ngModel)]="pallet.hauteur"
                                        [class]="(!isSaved && pallet?.hauteur==null) ? 'input-border-danger' : ''">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<div class="modal-footer">
    <button type="button" class="btn btn-success" (click)="savePallet()">{{'data.save' | translate }}</button>
    <button type="button" class="btn btn-secondary" (click)="closeModal()">{{'data.close' | translate }}</button>
</div>