<!-- <div class="AuthPageContainer">
    <div class="BackGroundContainer"></div>
    <div class="AuthInfoContainer">
      <div class="AuthInfoContent">
        <div class="logoContainer">
          <img src="./images/images.png" alt="companyLogo" />
        </div>
        <div class="authTitles">
          <a href="#">Log In</a>
          <a href="#">Sign Up</a>
        </div>
        <div class="authInputs">
          <input type="text" placeholder="Email Adress" />
          <input type="text" placeholder="Password" />
          <a href="#">Forget password ?</a>
          <button (click)="authentification()" class="authButton">Log In</button>
          <p>or</p>
          <button class="otherAuthButton facebook">
            <i class="fab fa-facebook"></i> Facebook
          </button>
          <button class="otherAuthButton google">
            <i class="fab fa-google"></i>Google
          </button>
      
          <h2>
            By clicking 'Log In', 'Facebook', 'Google' you agree to
            <span> the Wish Terms of Use and Privacy Policy</span> . This site
            is protected by reCAPTCHA and the Google
            <span>Privacy Policy and Terms of Service</span> apply.
          </h2>
        </div>
      </div>
    </div>
  </div> -->

<!-- template 1 --- 
  <div class="main">

    <section class="sign-in">
        <div class="container">
            <div class="signin-content">
                <div class="signin-image">
                    <figure><img src="assets/images/signin-image.jpg" alt="sing up image"></figure>
                    <a href="#" class="signup-image-link">Create an account</a>
                </div>

                <div class="signin-form">
                    <h2 class="form-title">Sign up</h2>
                    <form action="" method="POST" class="register-form" id="login-form" onsubmit="return false;"
                    (onsubmit)="authentification(username,password);">
                        <div class="form-group">
                            <label for="username"><i class="zmdi zmdi-account material-icons-name"></i></label>
                            <input type="text" name="username" id="username" placeholder="Your Name" #username ngModel/>
                        </div>
                        <div class="form-group">
                          <label class="msg-error">qsqdqsdqs</label>
                        </div>
                        <div class="form-group">
                            <label for="password"><i class="zmdi zmdi-lock"></i></label>
                            <input type="password" name="password" id="password" placeholder="Password" #password ngModel/>
                            <label class="msg-error" *ngIf="msgErrorPassword!=null">{{msgErrorPassword}}</label> <br />
                        </div>
                        <div class="form-group">
                            <input type="checkbox" name="remember-me" id="remember-me" class="agree-term" />
                            <label for="remember-me" class="label-agree-term"><span><span></span></span>Remember me</label>
                        </div>
                        <div class="form-group form-button">
                            <input type="submit" name="signin" id="signin" class="form-submit" value="Log in"
                            (click)="authentification(username,password)"/>
                        </div>
                    </form>
                    <div class="social-login">
                        <span class="social-label">Or login with</span>
                        <ul class="socials">
                            <li><a href="#"><i class="display-flex-center zmdi zmdi-facebook"></i></a></li>
                            <li><a href="#"><i class="display-flex-center zmdi zmdi-twitter"></i></a></li>
                            <li><a href="#"><i class="display-flex-center zmdi zmdi-google"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>

</div> -->



<div class="limiter">
  <div class="container-login100" style="background-image: url('assets/images/bg-01.jpg');">
    <div class="wrap-login100">
      <form class="login100-form validate-form" action="" method="POST" id="login-form" onsubmit="return false;"
      (onsubmit)="authentification(username,password);">
        <span class="login100-form-logo">
          <i class="zmdi zmdi-landscape"></i>
        </span>

        <span class="login100-form-title p-b-34 p-t-27">
          Log in
        </span>

        <div class="wrap-input100 validate-input" data-validate = "Enter username">
          <input class="input-login input100" type="text" name="username" placeholder="Username" #username ngModel>
          <span class="focus-input100" data-placeholder="&#xf207;"></span>
        </div>
        <div class="msg-error p-t-10">
          <label class="txt1 msg-error-color" *ngIf="msgErrorUsername!=null">
            {{msgErrorUsername}}
          </label>
        </div>
        
        <div class="wrap-input100 validate-input" data-validate="Enter password">
          <input class="input-login input100" type="password" name="pass" placeholder="Password" #password ngModel>
          <span class="focus-input100" data-placeholder="&#xf191;"></span>
        </div>
        <div class="msg-error p-t-10">
          <label class="txt1 msg-error-color" *ngIf="msgErrorPassword!=null">
            {{msgErrorPassword}}
          </label>
        </div>

        <div class="contact100-form-checkbox">
          <input class="input-checkbox100" id="ckb1" type="checkbox" name="remember-me">
          <label class="label-checkbox100" for="ckb1">
            Remember me
          </label>
        </div>

        <div class="container-login100-form-btn">
          <button type="submit" (click)="authentification(username,password)" class="button-login login100-form-btn">
            Login
          </button>
        </div>

        <div class="msg-error text-center p-t-20">
          <label class="txt1 msg-error-color " *ngIf="msgErrorLogin!=null">
            {{msgErrorLogin}}
          </label>
        </div>

        <div class="text-center p-t-90">
          <div class="txt1">
            Forgot Password?
          </div>
        </div>
      </form>
    </div>
  </div>
</div>


<div id="dropDownSelect1"></div>