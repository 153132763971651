<div class="app-sidebar bg-royal sidebar-shadow sidebar-text-light scroll-menu">
    <div class="app-header__logo">
        <div class="header__pane ml-auto">
            <div>
                <button type="button" class="hamburger close-sidebar-btn hamburger--elastic"
                    data-class="closed-sidebar">
                    <span class="hamburger-box">
                        <span class="hamburger-inner"></span>
                    </span>
                </button>
            </div>
        </div>
    </div>
    <div class="app-header__mobile-menu">
        <div>
            <button type="button" class="hamburger hamburger--elastic mobile-toggle-nav">
                <span class="hamburger-box">
                    <span class="hamburger-inner"></span>
                </span>
            </button>
        </div>
    </div>
    <div class="app-header__menu">
        <span>
            <button type="button"
                class="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav">
                <span class="btn-icon-wrapper">
                    <i class="fa fa-ellipsis-v fa-w-6"></i>
                </span>
            </button>
        </span>
    </div>
    <div class="scrollbar-sidebar">
        <div class="app-sidebar__inner">
            <ul class="vertical-nav-menu">
                <!-- <h2 >{{'data.details' | translate }}</h2> -->
                <li class="app-sidebar__heading space-top">{{'data.analysis' | translate }}</li>
                <li>
                    <a routerLink="/" routerLinkActive="mm-active"
                    [routerLinkActiveOptions]="{exact: true}"  (click)="closeToggleSidebarMobile()">
                        <i class="metismenu-icon pe-7s-graph2"></i>
                        {{'data.dashboard' | translate }}
                    </a>
                    <a [routerLink]="['/search']" routerLinkActive="mm-active" 
                    (click)="closeToggleSidebarMobile()">
                        <i class="metismenu-icon pe-7s-search"></i>
                        {{'data.search' | translate }}
                    </a>
                </li>
                <li class="app-sidebar__heading space-top">{{'data.establishment' | translate }}</li>
                <li>
                    <a [routerLink]="['/add-commande']" routerLinkActive="mm-active" 
                    (click)="closeToggleSidebarMobile()">
                        <i class="metismenu-icon pe-7s-box2"></i>
                        {{'data.add_removal' | translate }}
                    </a>
                    <a [routerLink]="['/multiple-commandes']" routerLinkActive="mm-active" 
                    (click)="closeToggleSidebarMobile()">
                        <i class="metismenu-icon pe-7s-next-2"></i>
                        {{'data.multiple_removals' | translate }}
                    </a>
                    <a [routerLink]="['/commandes']" routerLinkActive="mm-active" 
                    (click)="closeToggleSidebarMobile()">
                        <i class="metismenu-icon pe-7s-photo-gallery"></i>
                        {{'data.my_removals' | translate }}
                    </a>
                    <!-- <a href="/facture">
                        <i class="metismenu-icon pe-7s-box1"></i>
                        Facture
                    </a> -->
                </li>
                <li class="app-sidebar__heading space-top">{{'data.settings' | translate }}</li>
                <li>
                    <a routerLinkActive="mm-active" 
                    (click)="closeToggleSidebarMobile()">
                        <i class="metismenu-icon pe-7s-settings"></i>
                        {{'data.packaging_parameter' | translate }}
                    </a>
                    <a [routerLink]="['/all-products']" routerLinkActive="mm-active" 
                    (click)="closeToggleSidebarMobile()">
                        <i class="metismenu-icon pe-7s-box2"></i>
                        {{'data.boxes' | translate }}
                    </a>
                    <a [routerLink]="['/all-pallets']" routerLinkActive="mm-active" 
                    (click)="closeToggleSidebarMobile()">
                        <i class="metismenu-icon pe-7s-cart"></i>
                        {{'data.pallets' | translate }}
                    </a>
                    <!-- <a [routerLink]="['/detail-virements']" routerLinkActive="mm-active" 
                    (click)="closeToggleSidebarMobile()">
                        <i class="metismenu-icon pe-7s-cash"></i>
                        Détail virements
                    </a>
                    <a [routerLink]="['/virements-client']" routerLinkActive="mm-active" 
                    (click)="closeToggleSidebarMobile()">
                        <i class="metismenu-icon pe-7s-display2"></i>
                        Virements client
                    </a>
                    <a [routerLink]="['/statistics']" routerLinkActive="mm-active" 
                    (click)="closeToggleSidebarMobile()">
                        <i class="metismenu-icon pe-7s-server"></i>
                        Statistiques
                    </a> -->
                    <!-- <a href="#">
                        <i class="metismenu-icon pe-7s-id"></i>
                        Détail Client
                    </a> -->
                </li>
                <li class="app-sidebar__heading space-top">{{'data.claims' | translate }}</li>
                <li>
                    <a href="#">
                        <i class="metismenu-icon pe-7s-headphones"></i>
                        {{'data.my_claims' | translate }}
                    </a>
                </li>
                <!-- <li class="app-sidebar__heading">Utilisateurs</li>
                <li>
                    <a href="/add-user">
                        <i class="metismenu-icon pe-7s-add-user"></i>
                        Ajouter utilisateur
                    </a>
                    <a href="/preparers">
                        <i class="metismenu-icon pe-7s-tools"></i>
                        Preparateurs
                    </a>
                    <a href="/tracking-user">
                        <i class="metismenu-icon pe-7s-car"></i>
                        Transporteurs
                    </a>
                    <a href="#">
                        <i class="metismenu-icon pe-7s-users"></i>
                        Louagistes
                    </a>
                    <a href="#">
                        <i class="metismenu-icon pe-7s-home"></i>
                        Point de collectes
                    </a>
                </li> -->

                <!-- <li class="app-sidebar__heading">UI Components</li>
                <li>
                    <a href="#">
                        <i class="metismenu-icon pe-7s-diamond"></i>
                        Elements
                        <i class="metismenu-state-icon pe-7s-angle-down caret-left"></i>
                    </a>
                    <ul>
                        <li>
                            <a href="elements-buttons-standard.html">
                                <i class="metismenu-icon"></i>
                                Buttons
                            </a>
                        </li>
                        <li>
                            <a href="elements-dropdowns.html">
                                <i class="metismenu-icon">
                                </i>Dropdowns
                            </a>
                        </li>
                        <li>
                            <a href="elements-icons.html">
                                <i class="metismenu-icon">
                                </i>Icons
                            </a>
                        </li>
                        <li>
                            <a href="elements-badges-labels.html">
                                <i class="metismenu-icon">
                                </i>Badges
                            </a>
                        </li>
                        <li>
                            <a href="elements-cards.html">
                                <i class="metismenu-icon">
                                </i>Cards
                            </a>
                        </li>
                        <li>
                            <a href="elements-list-group.html">
                                <i class="metismenu-icon">
                                </i>List Groups
                            </a>
                        </li>
                        <li>
                            <a href="elements-navigation.html">
                                <i class="metismenu-icon">
                                </i>Navigation Menus
                            </a>
                        </li>
                        <li>
                            <a href="elements-utilities.html">
                                <i class="metismenu-icon">
                                </i>Utilities
                            </a>
                        </li>
                    </ul>
                </li>
                <li>
                    <a href="#">
                        <i class="metismenu-icon pe-7s-car"></i>
                        Components
                        <i class="metismenu-state-icon pe-7s-angle-down caret-left"></i>
                    </a>
                    <ul>
                        <li>
                            <a href="components-tabs.html">
                                <i class="metismenu-icon">
                                </i>Tabs
                            </a>
                        </li>
                        <li>
                            <a href="components-accordions.html">
                                <i class="metismenu-icon">
                                </i>Accordions
                            </a>
                        </li>
                        <li>
                            <a href="components-notifications.html">
                                <i class="metismenu-icon">
                                </i>Notifications
                            </a>
                        </li>
                        <li>
                            <a href="components-modals.html">
                                <i class="metismenu-icon">
                                </i>Modals
                            </a>
                        </li>
                        <li>
                            <a href="components-progress-bar.html">
                                <i class="metismenu-icon">
                                </i>Progress Bar
                            </a>
                        </li>
                        <li>
                            <a href="components-tooltips-popovers.html">
                                <i class="metismenu-icon">
                                </i>Tooltips &amp; Popovers
                            </a>
                        </li>
                        <li>
                            <a href="components-carousel.html">
                                <i class="metismenu-icon">
                                </i>Carousel
                            </a>
                        </li>
                        <li>
                            <a href="components-calendar.html">
                                <i class="metismenu-icon">
                                </i>Calendar
                            </a>
                        </li>
                        <li>
                            <a href="components-pagination.html">
                                <i class="metismenu-icon">
                                </i>Pagination
                            </a>
                        </li>
                        <li>
                            <a href="components-scrollable-elements.html">
                                <i class="metismenu-icon">
                                </i>Scrollable
                            </a>
                        </li>
                        <li>
                            <a href="components-maps.html">
                                <i class="metismenu-icon">
                                </i>Maps
                            </a>
                        </li>
                    </ul>
                </li>
                <li>
                    <a href="tables-regular.html">
                        <i class="metismenu-icon pe-7s-display2"></i>
                        Tables
                    </a>
                </li>
                <li class="app-sidebar__heading">Widgets</li>
                <li>
                    <a href="dashboard-boxes.html">
                        <i class="metismenu-icon pe-7s-display2"></i>
                        Dashboard Boxes
                    </a>
                </li>
                <li class="app-sidebar__heading">Forms</li>
                <li>
                    <a href="forms-controls.html">
                        <i class="metismenu-icon pe-7s-mouse">
                        </i>Forms Controls
                    </a>
                </li>
                <li>
                    <a href="forms-layouts.html">
                        <i class="metismenu-icon pe-7s-eyedropper">
                        </i>Forms Layouts
                    </a>
                </li>
                <li>
                    <a href="forms-validation.html">
                        <i class="metismenu-icon pe-7s-pendrive">
                        </i>Forms Validation
                    </a>
                </li>
                <li class="app-sidebar__heading">Charts</li>
                <li>
                    <a href="charts-chartjs.html">
                        <i class="metismenu-icon pe-7s-graph2">
                        </i>ChartJS
                    </a>
                </li>
                <li class="app-sidebar__heading">PRO Version</li>
                <li>
                    <a href="https://dashboardpack.com/theme-details/architectui-dashboard-html-pro/"
                        target="_blank">
                        <i class="metismenu-icon pe-7s-graph2">
                        </i>
                        Upgrade to PRO
                    </a>
                </li> -->
            </ul>
        </div>
        <div class="ps__rail-x" style="left: 0px; bottom: 0px;">
            <div class="ps__thumb-x" tabindex="0" style="left: 0px; width: 0px;"></div>
        </div>
        <div class="ps__rail-y" style="top: 0px; height: 662px; right: 0px;">
            <div class="ps__thumb-y" tabindex="0" style="top: 0px; height: 472px;"></div>
        </div>
    </div>
    <div class="app-sidebar-bg"></div>
</div>