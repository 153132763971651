import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientsService } from 'src/app/services/clients/clients.service';
import { CommandesService } from 'src/app/services/commandes/commandes.service';
import { SweetalertService } from 'src/app/services/sweetalert/sweetalert.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-detail-commande',
  templateUrl: './detail-commande.component.html',
  styleUrls: ['./detail-commande.component.css']
})
export class DetailCommandeComponent implements OnInit {

  isLoading: boolean = true
  env: any

  cmd: any = {}
  cmdID: string = '';
  statusCmds: any = []
  client: any = null

  constructor(private route: ActivatedRoute,
    private sweetalert: SweetalertService,
    private translate: TranslateService,
    private clientService: ClientsService,
    private cmdsService: CommandesService,
    private router: Router) {
    this.env = environment
  }

  async ngOnInit() {
    this.cmdsService.getLabeledStatus().then(
      (lst) => {
        this.statusCmds = lst
      }
    )

    await this.getInfosUser();
    this.route.queryParams.subscribe(async (params) => {
      this.cmdID = params.cmd;
      await this._fetchCmd();
      // console.log(this.cmd);

      if (params?.sended) {
        this.confirmArrivedCmd()
      }
    });
  }

  downloadFile(nomDuFichier) {
    // this.cmdsService.downloadFile(
    //   this.env?.uploadedFilesAssetsBaseURL + nomDuFichier, nomDuFichier);
  }

  goToNewTab(idCmd, route) {
    this.cmdsService.goToNewTab(idCmd, route)
  }

  async _fetchCmd() {
    // console.log(cid);
    await this.cmdsService.getCMD(this.cmdID).then((data: any) => {
      // console.log("dddddddddddddd")
      // console.log(data)

      if (data) {
        this.isLoading = false
        this.cmd = data;
      } else {
        this.translate.get('data.no_valid_order')
          .subscribe(async (text: string) => {
            alert(text)
          });
        location.href = '/commandes';
      }
    });
  }

  confirmArrivedCmd() {
    // console.log("this.cmdddd")
    // console.log(this.cmd)
    if (this.cmd?.etat_cmd == "validated")
      this.sweetalert.confirmActionBtn("Vous pourrez confirmer l'Enlèvement de la Marchandise par la société de transport !")
        // + this.cmd?.pickupedBy?.nameSociety + " !")
        .then((result: any) => {
          if (result.isConfirmed) {
            this.cmdsService.updateCmdStatus(this.cmdID, "take_off").then((res: any) => {
              if (res) {
                // console.log("successssssssss")

                this.sweetalert.alertSuccess("La commande a été modifié avec succès.").then(async (result: any) => {
                  if (result.isConfirmed) {
                    await this._fetchCmd()
                  }
                })
              }
            });
            // this.sweetalert.alertConfirmed().then((res: any) => {
            //   // location.href = "/product/product-list"
            //   this.allMissionDrivers(this.filterDate)
            // })
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            this.sweetalert.alertCanceled().then((res: any) => {
              // location.href = "/product/product-list"
            })
          }
        })
  }

  addComment(comment) {
    // console.log(cid);
    this.cmdsService.addComment(this.cmdID, this.client._id,
      this.client.prenom_client + " " + this.client.nom_client, "client", comment.value).then((data: any) => {
        // console.log("dddddddddddddd")
        // console.log(data)

        if (data) {
          this.cmd = data;
          comment.value = ""
        }
      });
  }

  async getInfosUser() {
    await this.clientService.getUserInfos().then((res: any) => {
      //console.log("getUserInfos");
      //console.log(res);
      if (res) {
        this.client = res;
      }
    });
  }
  generatePDF() {
    var divContents = document.getElementById("commande").innerHTML;
    var a = window.open('', '', 'height=860, width=1600');
    a.document.write('<html><head> ');
    a.document.write(
      '<link rel="stylesheet" type="text/css" href="/assets/css/bootstrap.min.css" />'
    );
    a.document.write(
      '<link rel="stylesheet" type="text/css" href="/assets/css/style.css" />'
    );
    a.document.write(
      '<link rel="stylesheet" type="text/css" href="/assets/css/components.css" />'
    );
    a.document.write(
      '</head><body style="background:#FFFFFF" ><div  class="container invoice">'
    );

    a.document.write(divContents);
    a.document.write('</div></body></html>');
    a.document.close();
    a.print();
  }

}
