<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <!-- <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                    <div class="widget-content-left mr-3">
                        <div class="widget-content-left">
                            <ngx-avatar class="rounded-circle" name="{{client.prenom_client}}
                            {{client.nom_client}}">
                            </ngx-avatar>
                        </div>
                    </div>
                    <div class="widget-content-left flex2">
                        <div class="widget-heading">{{client.prenom_client}}
                            {{client.nom_client}}</div>
                        <div class="widget-subheading opacity-7">{{client.company_name}}</div>
                    </div>
                </div>
            </div> -->

            <div class="page-title-icon">
                <i class="pe-7s-box2 icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>{{'data.pickups' | translate }}
                <div class="page-title-subheading">{{'data.customer_pickup_list' | translate }}
                    <strong>{{client?.prenom_client}}
                        {{client?.nom_client}}</strong>.
                </div>
            </div>
        </div>
        <!-- <div class="page-title-actions">
            <button type="button" data-toggle="tooltip" title="Example Tooltip" data-placement="bottom"
                class="btn-shadow mr-3 btn btn-dark">
                <i class="fa fa-star"></i>
            </button>
            <div class="d-inline-block dropdown">
                <button type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                    class="btn-shadow dropdown-toggle btn btn-info">
                    <span class="btn-icon-wrapper pr-2 opacity-7">
                        <i class="fa fa-business-time fa-w-20"></i>
                    </span>
                    Buttons
                </button>
                <div tabindex="-1" role="menu" aria-hidden="true"
                    class="dropdown-menu dropdown-menu-right">
                    <ul class="nav flex-column">
                        <li class="nav-item">
                            <a href="javascript:void(0);" class="nav-link">
                                <i class="nav-link-icon lnr-inbox"></i>
                                <span>
                                    Inbox
                                </span>
                                <div class="ml-auto badge badge-pill badge-secondary">86</div>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0);" class="nav-link">
                                <i class="nav-link-icon lnr-book"></i>
                                <span>
                                    Book
                                </span>
                                <div class="ml-auto badge badge-pill badge-danger">5</div>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0);" class="nav-link">
                                <i class="nav-link-icon lnr-picture"></i>
                                <span>
                                    Picture
                                </span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a disabled href="javascript:void(0);" class="nav-link disabled">
                                <i class="nav-link-icon lnr-file-empty"></i>
                                <span>
                                    File Disabled
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div> -->
    </div>
</div>

<div class="row">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="card-header">{{'data.filter' | translate }}
                <div class="btn-actions-pane-right">
                    <!-- <div role="group" class="btn-group-sm btn-group">
                        <div class="badge badge-warning">{{statusCmds}}</div>
                    </div> -->
                </div>
            </div>
            <div class="tab-content">
                <div class="tab-pane tabs-animation fade show active" id="tab-content-0" role="tabpanel">
                    <div class="card-body">
                        <form class="" action="" method="post" onsubmit="return false;" (onsubmit)="filterCmds();">
                            <div class="form-row">
                                <div class="col-md-3">
                                    <div class="position-relative form-group">
                                        <legend for="society" class="">{{'data.company' | translate }}</legend>
                                        <select class="form-control" [(ngModel)]="filter.society" name="society"
                                            id="society">
                                            <option></option>
                                            <option [value]="soc?._id" *ngFor="let soc of allSocieties">
                                                {{soc?.name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="position-relative form-group">
                                        <legend for="ref" class="">{{'data.reference' | translate }}</legend>
                                        <input name="ref" id="ref" type="text" class="form-control"
                                            [(ngModel)]="filter.ref">
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="position-relative form-group">
                                        <legend for="stat" class="">{{'data.status' | translate }}</legend>
                                        <select class="form-control selectric" (change)="selectChangeStatus($event)">
                                            <option [value]="stat" *ngFor="let stat of status">
                                                {{stat}}</option>
                                        </select>
                                    </div>
                                </div>
                                <!-- <div class="col-md-2">
                                    <div class="position-relative form-group">
                                        <legend for="numero" class="">Numéro de téléphone
                                        </legend>
                                        <input name="numero" id="numero" type="text" class="form-control" #numero
                                            ngModel>
                                    </div>
                                </div> -->
                                <div class="col-md-2">
                                    <div class="position-relative form-group">
                                        <legend for="datein" class="">{{'data.start_date' | translate }}</legend>
                                        <input id="datein" type="date" class="form-control" name="datein" tabindex="1"
                                            [(ngModel)]="filter.startDate">
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="position-relative form-group">
                                        <legend for="datefin" class="">{{'data.end_date' | translate }}</legend>
                                        <input id="datefin" type="date" class="form-control" name="datefin" tabindex="1"
                                            [(ngModel)]="filter.endDate">
                                    </div>
                                </div>
                                <button type="submit" (click)="filterCmds()"
                                    class="btn btn-primary btn-lg btn-icon icon-righ btn-search" id="search"
                                    tabindex="4">
                                    <i class="fas fa-search"></i>
                                </button>
                                <!-- <div class="col-md-1">
                                        <div class="position-relative form-group">
                                            <legend for="firstName" class="">dd</legend>
                                            <button class="mb-2 mr-2 btn btn-primary">
                                                <i class="nav-link-icon pe-7s-search"></i>
                                            </button>
                                        </div>
                                    </div> -->
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="isLoading; else elseAllCmds">
    <div class="row">
        <div class="col-md-12">
            <div class="main-card mb-3 card">
                <div class="card-body text-center">
                    <div class="spinner-container blue-spinner">
                        <mat-progress-spinner mode="indeterminate" [diameter]="40"
                            [strokeWidth]="5"></mat-progress-spinner>
                    </div>
                </div>
                <!-- <div style="height: 70px;" [nbSpinner]="loading" nbSpinnerStatus="warning" nbSpinnerSize="giant">
            </div> -->
            </div>
        </div>
    </div>
</div>
<ng-template #elseAllCmds>
    <div class="row">
        <div class="col-md-12">
            <div class="main-card mb-3 card">
                <div class="card-header">{{'data.pickups' | translate }}
                    <!-- <div class="btn-actions-pane-right">
                    <div role="group" class="btn-group-sm btn-group">
                        <button class="active btn btn-focus">Last Week</button>
                        <button class="btn btn-focus">All Month</button>
                    </div>
                </div> -->
                </div>
                <div class="card-body">
                    <div class="tab-content">
                        <div class="table-responsive">
                            <div class="budget-price justify-content-center" *ngIf="cmdList?.length === 0">
                                <h2 class="text-center" style="color: red;">{{'data.no_pickup_created' | translate }}
                                </h2>
                            </div>
                            <table class="align-middle mb-0 table table-borderless table-striped table-hover"
                                *ngIf="cmdList?.length > 0">
                                <thead>
                                    <tr>
                                        <th class="text-center">#</th>
                                        <th>{{'data.company' | translate }}</th>
                                        <th>{{'data.reference' | translate }}</th>
                                        <th class="text-center">{{'data.creation_date' | translate }}</th>
                                        <!-- <th class="text-center">Nom Client<br>&<br>Téléphone</th>
                                    <th class="text-center">Ville</th> -->
                                        <th class="text-center">
                                            {{'data.products' | translate }}<br>
                                            {{'data.quantity_product_name' | translate }}
                                        </th>
                                        <th>{{'data.comments' | translate }}</th>
                                        <th class="text-center">{{'data.status' | translate }}</th>
                                        <th class="text-center">{{'data.total_volume' | translate }}
                                            ({{'data.cubic_meters' | translate }})</th>
                                        <th class="text-center">{{'data.total_weight' | translate }} ({{'data.kg' |
                                            translate }})</th>
                                        <th class="text-center">{{'data.actions' | translate }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let cmd of cmdList, let index = index">
                                        <td class="text-center text-muted">#{{index+1}}</td>
                                        <td>
                                            <div class="widget-content p-0">
                                                <div class="widget-content-wrapper">
                                                    <div class="widget-content-left mr-3">
                                                        <div class="widget-content-left">
                                                            <img width="40" class="rounded-circle"
                                                                [src]="cmd?.society?.logo" alt="Logo"
                                                                style="border: 1px solid #000;">
                                                        </div>
                                                    </div>
                                                    <div class="widget-content-left flex2">
                                                        <div class="widget-heading">
                                                            {{cmd?.society?.name}}
                                                        </div>
                                                        <div class="widget-subheading opacity-7">
                                                            {{cmd?.society?.pays}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="text">{{cmd?.ref_facture_cmd}}</td>
                                        <td class="text-center">
                                            {{cmd?.date_creation_cmd | date:'d MMM y'}}<br>
                                            <b>{{cmd?.date_creation_cmd | date:'HH:mm'}}</b>
                                        </td>
                                        <!-- <td class="text-center">
                                        <div class="widget-content p-0">
                                            <div class="widget-content-wrapper">
                                                <div class="widget-content-left flex2">
                                                    <div class="widget-heading">
                                                        {{cmd?.user_destination?.nom_destination}}</div>
                                                    <div class="widget-subheading opacity-7">
                                                        {{cmd?.user_destination?.numtel_destination}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        {{cmd?.user_destination?.adresse_destination}}<br>
                                        <strong>{{cmd?.destination_cmd}}</strong>
                                    </td> -->
                                        <td>
                                            <!-- <div *ngFor="let prd of cmd?.boxes, let index = index">
                                            {{prd?.quantite}} - {{prd?.nom_prod}}
                                        </div> -->
                                            <ul class="rm-list-borders rm-list-borders-scroll list-group list-group-flush"
                                                *ngIf="cmd?.boxes?.length > 0">
                                                <li class="list-group-item"
                                                    *ngFor="let box of cmd?.boxes, let index = index">
                                                    <div class="widget-content p-0">
                                                        <div class="widget-content-wrapper">
                                                            <div class="widget-content-left mr-3">
                                                                <img width="40" height="40"
                                                                    src="https://assets.marketbey.tn/WTM/products/box.png"
                                                                    alt="">
                                                                <!-- <ngx-avatar class="rounded-circle" name="{{driver?.travelerUser?.prenom_user}} {{driver?.travelerUser?.nom_user}}">
                                                </ngx-avatar> -->
                                                            </div>
                                                            <div class="widget-content-left">
                                                                <div class="widget-heading">
                                                                    {{box?.quantite}} - {{box?.nom_prod}}
                                                                </div>
                                                                <div class="widget-subheading">
                                                                    {{box?.des_prod}}
                                                                </div>
                                                            </div>
                                                            <div class="widget-content-right">
                                                                <div class="font-size-lg text-muted">
                                                                    <span>{{box?.quantite * box?.volume | number:
                                                                        '0.1'}}
                                                                        {{'data.cubic_meters' | translate }}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                            <ul class="rm-list-borders rm-list-borders-scroll list-group list-group-flush"
                                                *ngIf="cmd?.pallets?.length > 0">
                                                <li class="list-group-item"
                                                    *ngFor="let pallet of cmd?.pallets, let index = index">
                                                    <div class="widget-content p-0">
                                                        <div class="widget-content-wrapper">
                                                            <div class="widget-content-left mr-3">
                                                                <img width="40" height="40"
                                                                    src="https://assets.marketbey.tn/WTM/products/pallet.png"
                                                                    alt="">
                                                                <!-- <ngx-avatar class="rounded-circle" name="{{driver?.travelerUser?.prenom_user}} {{driver?.travelerUser?.nom_user}}">
                                                </ngx-avatar> -->
                                                            </div>
                                                            <div class="widget-content-left">
                                                                <div class="widget-heading">
                                                                    {{pallet?.quantity}} - {{pallet?.name_pallet}}
                                                                </div>
                                                                <!-- <div class="widget-subheading">
                                                                {{pallet?.driver_id?.user_transportor?.tel_user}}
                                                            </div> -->
                                                            </div>
                                                            <div class="widget-content-right">
                                                                <div class="font-size-lg text-muted">
                                                                    <span>{{pallet?.quantity * pallet?.volume | number:
                                                                        '0.1'}} m³</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </td>
                                        <td class="text-center">
                                            <span class="badge badge-pill badge-danger">{{cmd?.comments?.length}}</span>
                                        </td>
                                        <td class="text-center">
                                            <div class="badge badge-{{statusCmds[cmd?.etat_cmd]?.color}}">
                                                {{statusCmds[cmd?.etat_cmd]?.label}}</div>
                                        </td>
                                        <td class="text-center">
                                            {{ cmd?.totalPalletVolume | number:'0.1' }}
                                        </td>
                                        <td class="text-center">
                                            {{ cmd?.totalPoids | number:'0.1' }}
                                        </td>
                                        <!-- <td class="text-center">{{cmd?.total_ca_cmd + cmd?.frais_livraison |
                                        number:'0.3'}} EUR</td> -->

                                        <td class="text-center">
                                            <div class="mb-2 mr-2 dropright btn-group" ngbDropdown placement="left-top">
                                                <button class="btn-wide btn btn-info">{{'data.actions' | translate
                                                    }}</button>
                                                <button type="button" ngbDropdownToggle
                                                    class="dropdown-toggle-split dropdown-toggle btn btn-info"><span
                                                        class="sr-only">{{'data.actions' | translate }}</span></button>
                                                <div ngbDropdownMenu class="dropdown-menu">
                                                    <button type="button" (click)="goToNewTab(cmd?._id, '/detail-cmd')"
                                                        tabindex="0" class="dropdown-item">
                                                        <i class="metismenu-icon pe-7s-news-paper"
                                                            style="margin-right: 10px;"></i>{{'data.details' | translate
                                                        }}
                                                    </button>
                                                    <!-- <button type="button" tabindex="0"
                                                        (click)="goToNewTab(cmd?._id, '/facture')"
                                                        class="dropdown-item"><i class="metismenu-icon pe-7s-print"
                                                            style="margin-right: 10px;"></i>{{'data.bill' | translate }}</button> -->
                                                    <!-- <h6 tabindex="-1" class="dropdown-header">Header</h6> -->
                                                    <button type="button" [routerLink]="['/edit-commande']"
                                                        [queryParams]="{ cmd: cmd?._id }" tabindex="0"
                                                        class="dropdown-item">
                                                        <i class="metismenu-icon pe-7s-pen text-primary"
                                                            style="margin-right: 10px;"></i>{{'data.edit_removal' |
                                                        translate }}
                                                    </button>
                                                    <div *ngIf="cmd?.etat_cmd=='validated' || cmd?.etat_cmd=='invalid'">
                                                        <button type="button" tabindex="0" class="dropdown-item"
                                                            (click)="updateStatusCmd(cmd?.qr_code,'canceled')"><i
                                                                class="metismenu-icon pe-7s-close-circle"
                                                                style="margin-right: 10px;"></i>{{'data.cancel' |
                                                            translate }}</button>
                                                    </div>
                                                    <button
                                                        *ngIf="cmd?.etat_cmd=='validated' || cmd?.etat_cmd=='invalid' || cmd?.etat_cmd=='canceled'"
                                                        type="button" tabindex="0" class="dropdown-item"
                                                        (click)="deleteCmd(cmd?._id)">
                                                        <i class="metismenu-icon pe-7s-trash text-danger"
                                                            style="margin-right: 10px;"> {{'data.delete' | translate }}
                                                        </i>
                                                    </button>
                                                    <!-- <div tabindex="-1" class="dropdown-divider"></div> -->
                                                    <!-- <button type="button" tabindex="0"
                                                    class="dropdown-item">Dividers</button> -->
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="d-block text-center card-footer" *ngIf="cmdList?.length > 0 && pages?.length > 1">
                            <nav aria-label="Page navigation example">
                                <ul class="pagination justify-content-center">
                                    <li [ngClass]="(currentPage===0)?'page-item disabled':'page-item'">
                                        <a class="page-link" tabindex="-1"
                                            (click)="previousPage(ref, numero, datein, datefin)">
                                            <i class="nav-link-icon pe-7s-angle-left-circle"></i>
                                            {{'data.previous' | translate }}</a>
                                    </li>

                                    <div *ngFor="let p of pagesDisplaied">
                                        <li [ngClass]="(currentPage===p)?'page-item active':'page-item'">
                                            <a *ngIf="currentPage!==p" class="page-link"
                                                (click)="selectPage(p, ref, numero, datein, datefin)">{{p+1}}</a>

                                            <span *ngIf="currentPage===p" class="page-link">
                                                {{p+1}}
                                                <span class="sr-only">(current)</span>
                                            </span>
                                        </li>
                                    </div>
                                    <!-- <li class="page-item active">
                                    <span class="page-link">
                                        2
                                        <span class="sr-only">(current)</span>
                                    </span>
                                </li>
                                <li class="page-item"><a class="page-link" href="#">3</a></li> -->

                                    <li [ngClass]="(currentPage===totalPages-1)?'page-item disabled':'page-item'">
                                        <a class="page-link" tabindex="-1"
                                            (click)="nextPage(ref, numero, datein, datefin)">{{'data.next' | translate
                                            }} <i class="nav-link-icon pe-7s-angle-right-circle"></i></a>
                                    </li>
                                </ul>
                            </nav>

                            <!-- <button class="mr-2 btn-icon btn-icon-only btn btn-outline-danger"><i
                                class="pe-7s-trash btn-icon-wrapper"> </i></button>
                        <button class="btn-wide btn btn-success">Save</button> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>