<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-box2 icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>{{'data.pickup' | translate }}
                <div class="page-title-subheading">{{'data.add_new_pickup' | translate }}
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="card-header-tab card-header">
                <div class="card-header-title">
                    <i class="header-icon lnr-rocket icon-gradient bg-tempting-azure"> </i>
                    {{'data.sender' | translate }}
                </div>
                <!-- <div class="btn-actions-pane-right">
                    <input hidden type="file" (change)="onFileChange($event)" multiple="false" #file>
                    <button type="button" (click)="file.click()" data-toggle="tooltip" title="Example Tooltip" data-placement="bottom"
                        class="btn-shadow mr-3 btn btn-info">
                        <i class="fa fa-star"> Exporter mes commandes</i>
                    </button>
                </div> -->
            </div>
            <div class="card-body">
                <!-- <form class="" action="" method="post" onsubmit="return false;"
                    (onsubmit)="addCommande(nameDest, adressDest, numTelDest);"> -->
                <div class="position-relative row form-group">
                    <div class="col-sm-1"></div>
                    <div class="col-sm-5">
                        <!-- <legend for="client" class="space-bottom">L'expéditeur</legend> -->
                        <input name="nameCompany" id="nameCompany" type="text" disabled class="form-control"
                            [value]="client?.prenom_client">
                    </div>

                    <div class="col-sm-5">
                        <input name="nameExp" id="nameExp" type="text" disabled class="form-control"
                            [value]="client?.nom_client">
                    </div>
                </div>
                <!-- </form> -->

            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="card-header-tab card-header">
                <div class="card-header-title">
                    <i class="header-icon lnr-rocket icon-gradient bg-tempting-azure"> </i>
                    {{'data.pickup_detail' | translate }}
                </div>
                <!-- <div class="btn-actions-pane-right">
                <input hidden type="file" (change)="onFileChange($event)" multiple="false" #file>
                <button type="button" (click)="file.click()" data-toggle="tooltip" title="Example Tooltip" data-placement="bottom"
                    class="btn-shadow mr-3 btn btn-info">
                    <i class="fa fa-star"> Exporter mes commandes</i>
                </button>
            </div> -->
            </div>
            <div class="card-body">
                <form>
                    <div class="row form-group">
                        <div class="col-md-4">
                            <div class="position-relative form-group">
                                <legend for="society" class="">{{'data.client' | translate }}</legend>
                                <select class="form-control" [(ngModel)]="order.society" name="society" id="society"
                                    [class]="(!isSaved && (order.society=='' || order.society==null)) ? 'input-border-danger' : ''">
                                    <!-- <option [value]="null">{{'data.select_company' | translate }}</option> -->
                                    <option [value]="item?.society?._id" *ngFor="let item of client?.societies">
                                        {{item?.society?.name}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="position-relative form-group">
                                <legend for="refFacture" class="">{{'data.pickup_reference' | translate }}</legend>
                                <input name="refFacture" id="refFacture" type="text" class="form-control"
                                    [(ngModel)]="order.refFacture"
                                    [class]="(!isSaved && (order.refFacture=='' || order.refFacture==null)) ? 'input-border-danger' : ''">
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="position-relative form-group">
                                <legend for="pickupDate" class="">{{'data.pickup_date' | translate }}</legend>

                                <div class="row">
                                    <div class="col-12 col-md-8">
                                        <input name="pickupDate" id="pickupDate" type="date" class="form-control"
                                            [(ngModel)]="order.pickupDate"
                                            [class]="(!isSaved && (order.pickupDate=='' || order.pickupDate==null)) ? 'input-border-danger' : ''">
                                    </div>
                                    <div class="col-12 col-md-4">
                                        <input name="pickupTime" id="pickupTime" type="time" class="form-control"
                                            [(ngModel)]="order.pickupTime"
                                            [class]="(!isSaved && (order.pickupTime=='' || order.pickupTime==null)) ? 'input-border-danger' : ''">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="position-relative form-group">
                                <legend for="priority" class="">{{'data.delivery_priority' | translate }}</legend>
                                <select class="form-control" [(ngModel)]="order.priority" name="priority" id="priority"
                                    [class]="(!isSaved && (order.priority=='' || order.priority==null)) ? 'input-border-danger' : ''">
                                    <!-- <option [value]="null">Sélectionnez la poste du contact</option> -->
                                    <option [ngValue]="item" *ngFor="let item of typesPriority">
                                        {{item.label}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <!-- <div class="col-md-6">
                            <div class="position-relative form-group">
                                <legend for="lastName" class="">Prix total</legend>
                                <input name="totalCmd" id="totalCmd" type="number" class="form-control" disabled
                                    [(ngModel)]="totalCmd">
                            </div>
                        </div> -->
                    </div>
                </form>
                <!-- <div class="container">
                    <div class="row form-group">
                        <legend for="client" class="space-bottom">Client destinataire</legend>

                        <div class="col-sm-4">
                            <input name="nameDest" id="nameDest" type="text" class="form-control"
                                placeholder="Nom de destinataire" #nameDest ngModel>
                            <mat-error style="color: red;font-weight: bold;" *ngIf="nameDest.value==''">
                                Ajoutez le nom du client
                            </mat-error>
                        </div>

                        <div class="col-sm-4">
                            <input name="adressDest" id="adressDest" type="text" class="form-control"
                                placeholder="Adresse du client" #adressDest ngModel>
                            <mat-error style="color: red;font-weight: bold;" *ngIf="adressDest.value==''">
                                Ajoutez l'adresse du client
                            </mat-error>
                        </div>

                        <div class="col-sm-4">
                            <input name="numTelDest" id="numTelDest" type="number" class="form-control"
                                placeholder="Numéro de téléphone" #numTelDest ngModel>
                            <mat-error style="color: red;font-weight: bold;"
                                *ngIf="numTelDest.value=='' || numTelDest.value.length!=8">
                                Ajoutez le numéro de téléphone du client
                            </mat-error>
                        </div>
                    </div>

                    <div class="row form-group">
                        <legend for="destination" class="space-bottom">Commande </legend>

                        <div class="col-sm-6">
                            <select class="form-control" (change)="selectVille($event)">
                                <option [value]="ville" *ngFor="let ville of villes">
                                    {{ville}}</option>
                            </select>
                            <mat-error style="color: red;font-weight: bold;"
                                *ngIf="selectedVille=='' || selectedVille=='Selectionnez une ville'">
                                Ajoutez la ville d'adresse
                            </mat-error>
                        </div>

                        <div class="col-sm-6">
                            <input name="totalCmd" id="totalCmd" type="number" class="form-control" disabled
                                [(ngModel)]="totalCmd">
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="card-header-tab card-header">
                <div class="card-header-title">
                    <i class="header-icon lnr-rocket icon-gradient bg-tempting-azure"> </i>
                    {{'data.file_upload' | translate }}
                </div>
                <div class="btn-actions-pane-right">
                    <!-- <input type="file" (change)="onFileSelected($event)" multiple> -->

                    <input hidden type="file" (change)="onFileSelected($event)" multiple #file>
                    <button type="button" (click)="file.click()" data-toggle="tooltip" title="Example Tooltip"
                        data-placement="bottom" class="btn-shadow mr-3 btn btn-info">
                        <i class="fa fa-star"> {{'data.import_files' | translate }}</i>
                    </button>
                </div>
            </div>
            <div class="card-body">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-md-3 text-center" *ngFor="let file of allFiles, let index = index">
                            {{file}} <i class="fa fa-times-circle text-danger" (click)="deleteDoc(index)"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row" [class.disabled-div]="disableBoxForm">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="card-header-tab card-header">
                <div class="card-header-title">
                    <i class="header-icon lnr-rocket icon-gradient bg-tempting-azure"> </i>
                    {{'data.boxes' | translate }}
                </div>
                <div class="btn-actions-pane-right">
                    <!-- <button type="button" (click)="calculatePallets()" class="mr-3 btn btn-danger"><i
                            class="pe-7s-shuffle"></i> Calculer</button> -->
                    <div class="mr-3 dropright btn-group" ngbDropdown placement="left-top">
                        <button class="btn-wide btn btn-danger">{{'data.calculate' | translate }}</button>
                        <button type="button" ngbDropdownToggle
                            class="dropdown-toggle-split dropdown-toggle btn btn-danger"><span
                                class="sr-only">Actions</span></button>
                        <div ngbDropdownMenu class="dropdown-menu">
                            <button *ngFor="let pal of allPalettes; let x = index" type="button"
                                (click)="calculatePallets(pal)" tabindex="0" class="dropdown-item">
                                <i class="metismenu-icon pe-7s-news-paper" style="margin-right: 10px;"></i>
                                {{pal?.name}} -
                                <b>{{pal?.longueur + "X" + pal?.largeur + "X" +
                                    pal?.hauteur}}</b>
                            </button>
                            <!-- <button type="button"
                            (click)="calculatePallets()"
                                tabindex="0"
                                class="dropdown-item">
                                <i class="metismenu-icon pe-7s-news-paper"
                                    style="margin-right: 10px;"></i>Palette standard
                            </button> -->
                        </div>
                    </div>

                    <button type="button" (click)="addNewBoxGroup()" class="mt-1 btn btn-warning"><i
                            class="pe-7s-plus"></i> {{'data.add_box' | translate }}</button>
                </div>
            </div>
            <div class="card-body">
                <div class="container">
                    <div [formGroup]="boxform">
                        <div formArrayName="boxes">
                            <div *ngFor="let box of boxes.controls; let i = index">
                                <div class="position-relative row form-group" [formGroupName]="i"
                                    style="margin-bottom: 25px;">

                                    <div class="col-12" style="margin-bottom: 8px;">
                                        <div class="row form-group">
                                            <div class="col-12 col-md-3">
                                                <div class="d-flex align-items-center">
                                                    <select class="form-control mr-2"
                                                        placeholder="{{'data.product_reference' | translate }}"
                                                        formControlName="nom_prod"
                                                        (change)="selectBoxProductRef($event, i)">
                                                        <option>{{'data.product_reference' | translate }}</option>
                                                        <option *ngFor="let box of client.boxes_products"
                                                            [value]="box?.ref">
                                                            {{ box.ref }}
                                                        </option>
                                                    </select>

                                                    <button type="button" data-toggle="tooltip" title="Example Tooltip"
                                                        (click)="newBoxProduct(i)" data-placement="bottom"
                                                        class="btn-shadow btn btn-dark">
                                                        <i class="fa fa-plus"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-3">
                                                <input type="text" class="form-control"
                                                    placeholder="{{'data.product_designation' | translate }}"
                                                    formControlName="des_prod">
                                            </div>
                                            <div class="col-12 col-md-5">
                                                <div class="row">
                                                    <div class="col-12 col-md-4">
                                                        <input type="number" class="form-control"
                                                            placeholder="{{'data.number_of_feet' | translate }}"
                                                            formControlName="nbrPcs"
                                                            (input)="updateQuantity($event, box, i)">
                                                    </div>

                                                    <div class="col-12 col-md-4">
                                                        <input type="number" class="form-control"
                                                            placeholder="{{'data.quantity' | translate }}"
                                                            formControlName="quantite" readonly>
                                                    </div>

                                                    <div class="col-12 col-md-4">
                                                        <input type="number" class="form-control"
                                                            placeholder="{{'data.carton_weight' | translate }}"
                                                            formControlName="weight">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-12 col-md-1">
                                                <button type="button" (click)="deleteBoxGroup(i)"
                                                    class="mr-2 btn-icon btn-icon-only btn btn-danger float-right"><i
                                                        class="pe-7s-trash btn-icon-wrapper"> </i></button>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12">
                                        <div class="row form-group">
                                            <div class="col-12 col-md-3">
                                                <input type="number" class="form-control"
                                                    placeholder="{{'data.length' | translate }}"
                                                    formControlName="length" (input)="setVolumeCmd(i)">
                                                <span class="cm">{{'data.meters' | translate }}</span>
                                            </div>

                                            <div class="col-12 col-md-3">
                                                <input type="number" class="form-control"
                                                    placeholder="{{'data.width' | translate }}" formControlName="width"
                                                    (input)="setVolumeCmd(i)">
                                                <span class="cm">{{'data.meters' | translate }}</span>
                                            </div>

                                            <div class="col-12 col-md-3">
                                                <input type="number" class="form-control"
                                                    placeholder="{{'data.height' | translate }}"
                                                    formControlName="height" (input)="setVolumeCmd(i)">
                                                <span class="cm">{{'data.meters' | translate }}</span>
                                            </div>

                                            <div class="col-12 col-md-3">
                                                <input type="number" class="form-control"
                                                    placeholder="{{'data.volume' | translate }}"
                                                    formControlName="volume" readonly>
                                                <span class="cm">{{'data.cubic_meters' | translate }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container" *ngIf="palletsData?.length > 0">
                    <div class="row">
                        <div class="col-12 col-md-4" *ngFor="let pallet of palletsData">
                            <div class="pallet" style="margin: 5px;">
                                <div class="pallet-info">
                                    <h3>{{'data.pallet' | translate }} {{pallet?.palletNumber}}:</h3>
                                    <p>{{'data.pallet_ratio' | translate }}: {{pallet?.dimensions?.width |
                                        number:'0.1'}} X
                                        {{pallet?.dimensions?.depth | number:'0.1'}} X {{pallet?.dimensions?.height |
                                        number:'0.1'}} {{'data.meters' | translate }}</p>
                                    <p>{{'data.total_boxes' | translate }}: {{pallet?.countBoxes}}</p>
                                    <div>
                                        <button class="expand-btn">▼</button>
                                        <p>{{'data.total_layer' | translate }}: {{pallet?.layers?.length}}</p>
                                        <ul class="product-id-list">
                                            <li *ngFor="let layer of pallet?.layers; let i = index">
                                                {{'data.layer' | translate }} {{layer?.layerNumber}}: (H)
                                                {{layer?.layerHeight}}
                                                <div class="row">
                                                    <div class="col-12 col-md-6" *ngFor="let item of layer?.boxes">
                                                        {{ '(' + item?.count + ') - ' + item?.box?.nom_prod }}</div>
                                                </div>
                                            </li>
                                            <!-- <li>Layer 1: (11, 12, 13, 14)</li> -->
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="row">
                        <mat-error style="color: red;font-weight: bold;"
                            *ngIf="boxform.value.boxes.length == 0 || (boxform.value.boxes.length > 0 && !boxesNotEmpty())">
                            Les produits sont invalid
                        </mat-error><br>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row" [class.disabled-div]="disablePalletForm">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="card-header-tab card-header">
                <div class="card-header-title">
                    <i class="header-icon lnr-rocket icon-gradient bg-tempting-azure"> </i>
                    {{'data.pallets' | translate }}
                </div>
                <div class="btn-actions-pane-right">
                    <button type="button" (click)="addNewPalletGroup()" class="mt-1 btn btn-warning"><i
                            class="pe-7s-plus"></i> {{'data.add_new_pallet' | translate }}</button>
                </div>
            </div>
            <div class="card-body">
                <div class="container">
                    <div [formGroup]="palletForm">
                        <div formArrayName="pallets">
                            <div *ngFor="let pallet of pallets.controls; let i = index">
                                <div class="position-relative row form-group" [formGroupName]="i"
                                    style="margin-bottom: 25px;">

                                    <div class="col-12" style="margin-bottom: 12px;">
                                        <div class="row form-group">
                                            <div class="col-12 col-md-5">
                                                <div class="custom-radio custom-control"
                                                    *ngFor="let pal of allPalettes; let x = index">
                                                    <input type="radio" [id]="pal._id + '-' + i" [name]="'group-' + i"
                                                        class="custom-control-input"
                                                        (click)="setTypePalette(pal._id, i)">
                                                    <label class="custom-control-label" [for]="pal._id + '-' + i">
                                                        {{pal?.name}} -
                                                        <b>{{pal?.longueur + "X" + pal?.largeur + "X" +
                                                            pal?.hauteur}}</b>
                                                    </label>
                                                </div>
                                                <div class="custom-radio custom-control">
                                                    <input type="radio" [id]="'other-' + i" [name]="'group-' + i"
                                                        class="custom-control-input"
                                                        (click)="setTypePalette('other', i)">
                                                    <label class="custom-control-label" [for]="'other-' + i">
                                                        {{'data.other' | translate }}
                                                    </label>
                                                </div>

                                            </div>
                                            <!-- <div class="col-12 col-md-2">
                                                
                                            </div> -->
                                            <div class="col-12 col-md-3">
                                                <input type="number" class="form-control"
                                                    placeholder="{{'data.quantity' | translate }}"
                                                    formControlName="quantity">
                                            </div>

                                            <!-- <div class="col-12 col-md-3">
                                                <input type="number" class="form-control" placeholder="Prix unitaire"
                                                    formControlName="prix_unitaire" (input)="setTotalCmd()">
                                            </div> -->

                                            <div class="col-12 col-md-3">
                                                <input type="number" class="form-control"
                                                    placeholder="{{'data.pallet_weight' | translate }}"
                                                    formControlName="weight">
                                            </div>

                                            <div class="col-12 col-md-1">
                                                <button type="button" (click)="deletePalletGroup(i)"
                                                    class="mr-2 btn-icon btn-icon-only btn btn-danger float-right"><i
                                                        class="pe-7s-trash btn-icon-wrapper"> </i></button>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12" style="margin-bottom: 30px; overflow: hidden;"
                                        *ngIf="getTypePalette(i)=='other'">
                                        <div class="row form-group">
                                            <div class="col-12 col-md-3">
                                                <input type="number" class="form-control"
                                                    placeholder="{{'data.length' | translate }}"
                                                    formControlName="length" (input)="setVolumePallet(i)">
                                                <span class="cm">{{'data.meters' | translate }}</span>
                                            </div>

                                            <div class="col-12 col-md-3">
                                                <input type="number" class="form-control"
                                                    placeholder="{{'data.width' | translate }}" formControlName="width"
                                                    (input)="setVolumePallet(i)">
                                                <span class="cm">{{'data.meters' | translate }}</span>
                                            </div>

                                            <div class="col-12 col-md-3">
                                                <input type="number" class="form-control"
                                                    placeholder="{{'data.height' | translate }}"
                                                    formControlName="height" (input)="setVolumePallet(i)">
                                                <span class="cm">{{'data.meters' | translate }}</span>
                                            </div>

                                            <div class="col-12 col-md-3">
                                                <input type="number" class="form-control"
                                                    placeholder="{{'data.volume' | translate }}"
                                                    formControlName="volume" readonly>
                                                <span class="cm">{{'data.cubic_meters' | translate }}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row" style="width: 105%;">
                                        <div class="col-12 col-md-12">
                                            <div class="main-card mb-3 card" style="border: 1px solid red;">
                                                <div class="card-header-tab card-header">
                                                    <div class="card-header-title">
                                                        <i
                                                            class="header-icon lnr-rocket icon-gradient bg-tempting-azure">
                                                        </i>
                                                        {{'data.box' | translate }} {{i+1}} :
                                                    </div>
                                                    <div class="btn-actions-pane-right">
                                                        <button type="button" (click)="addContent(i)"
                                                            class="mt-1 btn btn-warning"><i class="pe-7s-plus"></i>
                                                            {{'data.add_box' | translate }}
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="card-body" *ngIf="pallet.get('boxes').controls?.length > 0">
                                                    <div formArrayName="boxes">
                                                        <div
                                                            *ngFor="let content of pallet.get('boxes').controls; let j = index">
                                                            <div class="position-relative row form-group"
                                                                [formGroupName]="j" style="margin-bottom: 25px;">
                                                                <div class="col-12" style="margin-bottom: 8px;">
                                                                    <div class="row form-group">
                                                                        <!-- <div class="col-12 col-md-3">
                                                                            <select class="form-control"
                                                                                placeholder="{{'data.product_reference' | translate }}"
                                                                                formControlName="nom_prod"
                                                                                (change)="selectPalletBoxProductRef($event, i, j)">
                                                                                <option>{{'data.product_reference' |
                                                                                    translate }}</option>
                                                                                <option
                                                                                    *ngFor="let box of client.boxes_products"
                                                                                    [value]="box?.ref">
                                                                                    {{ box.ref }}
                                                                                </option>
                                                                            </select>
                                                                        </div>
                                                                        <div class="col-12 col-md-3">
                                                                            <input type="text" class="form-control"
                                                                                placeholder="{{'data.product_designation' | translate }}"
                                                                                formControlName="des_prod">
                                                                        </div> -->

                                                                        <div class="col-12 col-md-8">
                                                                            <div class="row form-group">
                                                                                <div class="col-12 col-md-3">
                                                                                    <input type="number"
                                                                                        class="form-control"
                                                                                        placeholder="{{'data.length' | translate }}"
                                                                                        formControlName="length"
                                                                                        (input)="setVolumeBoxPallet(i, j)">
                                                                                    <span class="cm">{{'data.meters' |
                                                                                        translate
                                                                                        }}</span>
                                                                                </div>

                                                                                <div class="col-12 col-md-3">
                                                                                    <input type="number"
                                                                                        class="form-control"
                                                                                        placeholder="{{'data.width' | translate }}"
                                                                                        formControlName="width"
                                                                                        (input)="setVolumeBoxPallet(i, j)">
                                                                                    <span class="cm">{{'data.meters' |
                                                                                        translate
                                                                                        }}</span>
                                                                                </div>

                                                                                <div class="col-12 col-md-3">
                                                                                    <input type="number"
                                                                                        class="form-control"
                                                                                        placeholder="{{'data.height' | translate }}"
                                                                                        formControlName="height"
                                                                                        (input)="setVolumeBoxPallet(i, j)">
                                                                                    <span class="cm">{{'data.meters' |
                                                                                        translate
                                                                                        }}</span>
                                                                                </div>

                                                                                <div class="col-12 col-md-3">
                                                                                    <input type="number"
                                                                                        class="form-control"
                                                                                        placeholder="{{'data.volume' | translate }}"
                                                                                        formControlName="volume"
                                                                                        readonly>
                                                                                    <span
                                                                                        class="cm">{{'data.cubic_meters'
                                                                                        |
                                                                                        translate }}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div class="col-12 col-md-4">
                                                                            <div class="row">
                                                                                <!-- <div class="col-12 col-md-4">
                                                                                    <input type="number"
                                                                                        class="form-control"
                                                                                        placeholder="{{'data.number_of_feet' | translate }}"
                                                                                        formControlName="nbrPcs"
                                                                                        (input)="updatePalletQuantity($event, content, i, j)">
                                                                                </div> -->

                                                                                <div class="col-12 col-md-5">
                                                                                    <input type="number"
                                                                                        class="form-control"
                                                                                        placeholder="{{'data.nbr_boxes' | translate }}"
                                                                                        formControlName="quantite">
                                                                                </div>

                                                                                <div class="col-12 col-md-5">
                                                                                    <input type="number"
                                                                                        class="form-control"
                                                                                        placeholder="{{'data.carton_weight' | translate }}"
                                                                                        formControlName="weight">
                                                                                </div>

                                                                                <div class="col-12 col-md-2">
                                                                                    <button type="button"
                                                                                        (click)="deleteBoxPalletGroup(i, j)"
                                                                                        class="mr-2 btn-icon btn-icon-only btn btn-danger float-right"><i
                                                                                            class="pe-7s-trash btn-icon-wrapper">
                                                                                        </i>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <!-- <div class="col-12 col-md-1">
                                                                            <button type="button"
                                                                                (click)="deleteBoxPalletGroup(i, j)"
                                                                                class="mr-2 btn-icon btn-icon-only btn btn-danger float-right"><i
                                                                                    class="pe-7s-trash btn-icon-wrapper">
                                                                                </i>
                                                                            </button>
                                                                        </div> -->
                                                                    </div>
                                                                </div>

                                                                <!-- <div class="col-12">
                                                                    <div class="row form-group">
                                                                        <div class="col-12 col-md-3">
                                                                            <input type="number" class="form-control"
                                                                                placeholder="{{'data.length' | translate }}"
                                                                                formControlName="length"
                                                                                (input)="setVolumeBoxPallet(i, j)">
                                                                            <span class="cm">{{'data.meters' | translate
                                                                                }}</span>
                                                                        </div>

                                                                        <div class="col-12 col-md-3">
                                                                            <input type="number" class="form-control"
                                                                                placeholder="{{'data.width' | translate }}"
                                                                                formControlName="width"
                                                                                (input)="setVolumeBoxPallet(i, j)">
                                                                            <span class="cm">{{'data.meters' | translate
                                                                                }}</span>
                                                                        </div>

                                                                        <div class="col-12 col-md-3">
                                                                            <input type="number" class="form-control"
                                                                                placeholder="{{'data.height' | translate }}"
                                                                                formControlName="height"
                                                                                (input)="setVolumeBoxPallet(i, j)">
                                                                            <span class="cm">{{'data.meters' | translate
                                                                                }}</span>
                                                                        </div>

                                                                        <div class="col-12 col-md-3">
                                                                            <input type="number" class="form-control"
                                                                                placeholder="{{'data.volume' | translate }}"
                                                                                formControlName="volume" readonly>
                                                                            <span class="cm">{{'data.cubic_meters' |
                                                                                translate }}</span>
                                                                        </div>
                                                                    </div>
                                                                </div> -->
                                                            </div>
                                                            <!-- <input formControlName="name" placeholder="Content Name">
                                            <input formControlName="quantity" placeholder="Content Quantity"> -->
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="row">
                        <mat-error style="color: red;font-weight: bold;"
                            *ngIf="!palletsNotEmpty(palletForm.value.pallets)">
                            Les produits sont invalid
                        </mat-error><br>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="tab-content" id="tab-content">
                <div class="main-card mb-12 card">
                    <div class="card-body">
                        <div class="row form-group">
                            <div class="col-md-12">
                                <legend for="firstName" class="">Notes et Commentaires</legend>
                                <textarea name="text" id="exampleText" class="form-control" style="height: 132px;"
                                    [(ngModel)]="order.comment"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="tab-content" id="tab-content">
                <div class="main-card mb-12 card">
                    <div class="d-block text-center card-footer">
                        <div *ngIf="isClicked; else elseIsSaved">
                            <div class="spinner-container blue-spinner">
                                <mat-progress-spinner mode="indeterminate" [diameter]="40"
                                    [strokeWidth]="5"></mat-progress-spinner>
                            </div>
                        </div>
                        <ng-template #elseIsSaved>
                            <button type="submit" (click)="addCommande()" class="btn btn-primary btn-lg btn-block">
                                {{'data.add_new_pickup' | translate }}</button>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>