import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SettingsService } from 'src/app/services/settings/settings.service';
import { SweetalertService } from 'src/app/services/sweetalert/sweetalert.service';
import Swal from 'sweetalert2';
import { AddBoxComponent } from '../windows/add-box/add-box.component';

@Component({
  selector: 'app-all-products',
  templateUrl: './all-products.component.html',
  styleUrls: ['./all-products.component.css']
})
export class AllProductsComponent implements OnInit {

  isLoading: any = true;
  boxes: any[] = []

  currentPage: number = 0
  totalPages: number = 1
  pages: Array<number> = [];
  pagesDisplaied: Array<number> = [];

  box: any = {

  }

  constructor(private service: SettingsService,
    private sweetalert: SweetalertService,
    private modalService: NgbModal,) { }

  ngOnInit(): void {
    this._fetchBoxes()
  }

  async _fetchBoxes() {
    await this.service.allBoxesProducts({
      page: this.currentPage
    }).then((res: any) => {
      // console.log(res)
      if (res) {
        this.isLoading = false
        // this.boxes = res.data

        this.boxes = res.items;
        this.totalPages = res.totalPages
        this.currentPage = res.currentPage
        this.pages = Array.from(Array(res.totalPages)).map((x, i) => i)
        this.splicePages()
      }
    });
  }

  newBoxProduct() {
    var box = {
      "ref": "",
      "t_seak": "",
      "t_hght": null,
      "t_wdth": null,
      "t_dpth": null,
      "ucs": null,
    }
    this.saveBoxProduct(box)
  }
  saveBoxProduct(box: any) {
    // console.log(box)
    const modalRef = this.modalService.open(AddBoxComponent, { size: 'lg' });
    modalRef.componentInstance.box = box

    modalRef.componentInstance.result.subscribe((receivedEntry) => {
      // console.log(receivedEntry)
      if (receivedEntry) {
        // console.log("Result from modal:", receivedEntry);
        this._fetchBoxes()
      }
    });
  }
  deleteBoxProduct(idBox: any) {
    this.sweetalert.confirmActionBtn("Vous ne pourrez pas revenir en arrière !").then((result: any) => {
      if (result.isConfirmed) {
        this.service.deleteBoxProduct(idBox).then((res: any) => {
          if (res && res.response == true) {
            this.sweetalert.alertConfirmed(res?.msg).then((res: any) => {
              this._fetchBoxes()
            })
          }
        });
      } else if (
        result.dismiss === Swal.DismissReason.cancel
      ) {
        this.sweetalert.alertCanceled().then((res: any) => {
        })
      }
    })
  }

  splicePages() {
    if (this.pages.length > 10) {
      if (this.currentPage < 5) {
        this.pagesDisplaied = this.pages.splice(0, 10);
      } else if (this.currentPage >= 5 && this.currentPage < this.pages.length - 5) {
        this.pagesDisplaied = this.pages.splice(this.currentPage - 4, 10);
      } else {
        this.pagesDisplaied = this.pages.splice(this.pages.length - 10, 10);
      }
    } else {
      this.pagesDisplaied = this.pages
    }
  }

  nextPage() {
    this.currentPage++
    this._fetchBoxes()
  }

  previousPage() {
    this.currentPage--
    this._fetchBoxes()
  }

  selectPage(page) {
    this.currentPage = page
    this._fetchBoxes()
  }

}
