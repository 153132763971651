<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-search icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>{{'data.search' | translate }}
                <div class="page-title-subheading"><strong>{{'data.search_by' | translate }}</strong> 
                    {{'data.header_search_by' | translate }}
                </div>
            </div>
        </div>
        <div class="page-title-actions">
            <div class="d-inline-block dropdown">
                <div class="search-wrapper active">
                    <div class="input-holder">
                        <!-- <form class="needs-validation" action="" method="post" onsubmit="return false;"
                            (onsubmit)="listCmdsClient(keyword);"> -->
                        <input type="text" id="keyword" name="keyword" class="search-input" placeholder="{{'data.type_to_search' | translate }}"
                            #keyword ngModel>

                        <button type="button" (click)="listCmdsClient(keyword)"
                            class="search-icon"><span></span></button>
                        <!-- </form> -->
                    </div>
                    <!-- <button class="close"></button> -->
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="row">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="card-header">Filtre
                <div class="btn-actions-pane-right">
                </div>
            </div>
            <div class="tab-content">
                <div class="tab-pane tabs-animation fade show active" id="tab-content-0"
                    role="tabpanel">
                    <div class="card-body">
                        <form class="" action="" method="post" onsubmit="return false;"
                            (onsubmit)="filterCmds(ref, numero, datein, datefin);">
                            <div class="form-row">
                                <div class="col-md-2">
                                    <div class="position-relative form-group">
                                        <legend for="ref" class="">Référence</legend>
                                        <input name="ref" id="ref" type="text" class="form-control" #ref
                                            ngModel>
                                    </div>
                                </div>
                                <div class="col-md-1">
                                    <div class="position-relative form-group">
                                        <legend for="stat" class="">Status</legend>
                                        <select class="form-control selectric"
                                            (change)="selectChangeStatus($event)">
                                            <option [value]="stat" *ngFor="let stat of status">
                                                {{stat}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="position-relative form-group">
                                        <legend for="ville" class="">Ville</legend>
                                        <select class="form-control selectric"
                                            (change)="selectChangeVille($event)">
                                            <option [value]="ville" *ngFor="let ville of villes">
                                                {{ville}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="position-relative form-group">
                                        <legend for="numero" class="">Numéro de téléphone
                                        </legend>
                                        <input name="numero" id="numero" type="text"
                                            class="form-control" #numero ngModel>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="position-relative form-group">
                                        <legend for="datein" class="">Date Début</legend>
                                        <input id="datein" type="date" class="form-control"
                                            name="datein" tabindex="1" #datein ngModel>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="position-relative form-group">
                                        <legend for="datefin" class="">Date Fin</legend>
                                        <input id="datefin" type="date" class="form-control"
                                            name="datefin" tabindex="1" #datefin ngModel>
                                    </div>
                                </div>
                                <button type="submit" (click)="filterCmds(ref, numero, datein, datefin)"
                                    class="btn btn-primary btn-lg btn-icon icon-righ btn-search"
                                    id="search" tabindex="4">
                                    <i class="fas fa-search"></i>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<div class="row">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="card-header">{{'data.pickups' | translate }}
                <div class="btn-actions-pane-right">
                    <!-- <div role="group" class="btn-group-sm btn-group">
                        <div class="badge badge-warning">{{statusCmds}}</div>
                    </div> -->
                </div>
            </div>
            <div class="card-body">
                <div class="tab-content">
                    <div class="table-responsive">
                        <div class="budget-price justify-content-center" *ngIf="listCmds?.length === 0">
                            <h2 class="text-center" style="color: red;">{{'data.no_pickup_created' | translate }}</h2>
                        </div>
                        <table class="align-middle mb-0 table table-borderless table-striped table-hover"
                            *ngIf="listCmds?.length > 0">
                            <thead>
                                <tr>
                                    <th class="text-center">#</th>
                                    <th>{{'data.company' | translate }}</th>
                                    <th>{{'data.reference' | translate }}</th>
                                    <th class="text-center">{{'data.creation_date' | translate }}</th>
                                    <!-- <th class="text-center">Nom Client<br>&<br>Téléphone</th>
                                    <th class="text-center">Ville</th> -->
                                    <th class="text-center">
                                        {{'data.products' | translate }}<br>
                                        {{'data.quantity_product_name' | translate }}
                                    </th>
                                    <th class="text-center">{{'data.status' | translate }}</th>
                                    <!-- <th class="text-center">Total<br>Commande</th> -->
                                    <th class="text-center">{{'data.total_volume' | translate }} ({{'data.cubic_meters' | translate }})</th>
                                    <th class="text-center">{{'data.total_weight' | translate }} ({{'data.kg' | translate }})</th>
                                    <th class="text-center">{{'data.actions' | translate }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let cmd of listCmds, let index = index">
                                    <td class="text-center text-muted">#{{index+1}}</td>
                                    <td>
                                        <div class="widget-content p-0">
                                            <div class="widget-content-wrapper">
                                                <div class="widget-content-left mr-3">
                                                    <div class="widget-content-left">
                                                        <img width="40" class="rounded-circle"
                                                            [src]="cmd?.society?.logo" alt="Logo"
                                                            style="border: 1px solid #000;">
                                                    </div>
                                                </div>
                                                <div class="widget-content-left flex2">
                                                    <div class="widget-heading">
                                                        {{cmd?.society?.name}}
                                                    </div>
                                                    <div class="widget-subheading opacity-7">
                                                        {{cmd?.society?.pays}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="text">{{cmd.ref_facture_cmd}}</td>
                                    <td class="text-center">
                                        {{cmd.date_creation_cmd | date:'d MMM y'}}<br>
                                        <b>{{cmd.date_creation_cmd | date:'HH:mm'}}</b>
                                    </td>
                                    <!-- <td class="text-center">
                                        <div class="widget-content p-0">
                                            <div class="widget-content-wrapper">
                                                <div class="widget-content-left flex2">
                                                    <div class="widget-heading">
                                                        {{cmd.user_destination.nom_destination}}</div>
                                                    <div class="widget-subheading opacity-7">
                                                        {{cmd.user_destination.numtel_destination}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        {{cmd.user_destination.adresse_destination}}<br>
                                        <strong>{{cmd.destination_cmd}}</strong>
                                    </td> -->
                                    <td>
                                        <!-- <div *ngFor="let prd of cmd.products, let index = index">
                                            {{prd.quantite}} - {{prd.nom_prod}}
                                        </div> -->
                                        <ul class="rm-list-borders rm-list-borders-scroll list-group list-group-flush"
                                            *ngIf="cmd?.boxes?.length > 0">
                                            <li class="list-group-item"
                                                *ngFor="let box of cmd?.boxes, let index = index">
                                                <div class="widget-content p-0">
                                                    <div class="widget-content-wrapper">
                                                        <div class="widget-content-left mr-3">
                                                            <img width="40" height="40"
                                                                src="https://assets.marketbey.tn/WTM/products/box.png"
                                                                alt="">
                                                            <!-- <ngx-avatar class="rounded-circle" name="{{driver?.travelerUser?.prenom_user}} {{driver?.travelerUser?.nom_user}}">
                                                </ngx-avatar> -->
                                                        </div>
                                                        <div class="widget-content-left">
                                                            <div class="widget-heading">
                                                                {{box?.quantite}} - {{box?.nom_prod}}
                                                            </div>
                                                            <div class="widget-subheading">
                                                                {{box?.des_prod}}
                                                            </div>
                                                        </div>
                                                        <div class="widget-content-right">
                                                            <div class="font-size-lg text-muted">
                                                                <span>{{box?.quantite * box?.volume | number: '0.1'}}
                                                                    {{'data.cubic_meters' | translate }}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                        <ul class="rm-list-borders rm-list-borders-scroll list-group list-group-flush"
                                            *ngIf="cmd?.pallets?.length > 0">
                                            <li class="list-group-item"
                                                *ngFor="let pallet of cmd?.pallets, let index = index">
                                                <div class="widget-content p-0">
                                                    <div class="widget-content-wrapper">
                                                        <div class="widget-content-left mr-3">
                                                            <img width="40" height="40"
                                                                src="https://assets.marketbey.tn/WTM/products/pallet.png"
                                                                alt="">
                                                            <!-- <ngx-avatar class="rounded-circle" name="{{driver?.travelerUser?.prenom_user}} {{driver?.travelerUser?.nom_user}}">
                                                </ngx-avatar> -->
                                                        </div>
                                                        <div class="widget-content-left">
                                                            <div class="widget-heading">
                                                                {{pallet?.quantity}} - {{pallet?.name_pallet}}
                                                            </div>
                                                            <!-- <div class="widget-subheading">
                                                                {{pallet?.driver_id?.user_transportor?.tel_user}}
                                                            </div> -->
                                                        </div>
                                                        <div class="widget-content-right">
                                                            <div class="font-size-lg text-muted">
                                                                <span>{{pallet?.quantity * pallet?.volume | number:
                                                                    '0.1'}} {{'data.cubic_meters' | translate }}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </td>
                                    <td class="text-center">
                                        <div class="badge badge-{{statusCmds[cmd.etat_cmd].color}}">
                                            {{statusCmds[cmd.etat_cmd].label}}
                                            <!-- {{'data.actions' | translate }} -->
                                        </div>
                                    </td>

                                    <td class="text-center">
                                        {{ cmd?.totalPalletVolume | number:'0.1' }}
                                    </td>
                                    <td class="text-center">
                                        {{ cmd?.totalPoids | number:'0.1' }}
                                    </td>
                                    <!-- <td class="text-center">{{cmd.total_ca_cmd + cmd.frais_livraison |
                                        number:'0.3'}} EUR</td> -->
                                    <td class="text-center">
                                        <div class="mb-2 mr-2 dropright btn-group" ngbDropdown placement="left-top">
                                            <button class="btn-wide btn btn-info">{{'data.actions' | translate }}</button>
                                            <button type="button" ngbDropdownToggle
                                                class="dropdown-toggle-split dropdown-toggle btn btn-info"><span
                                                    class="sr-only">{{'data.actions' | translate }}</span></button>
                                            <div ngbDropdownMenu class="dropdown-menu">
                                                <button type="button" (click)="goToNewTab(cmd._id, '/detail-cmd')"
                                                    tabindex="0" class="dropdown-item">
                                                    <i class="metismenu-icon pe-7s-news-paper"
                                                        style="margin-right: 10px;"></i>{{'data.details' | translate }}
                                                </button>
                                                <button type="button" [routerLink]="['/edit-commande']"
                                                    [queryParams]="{ cmd: cmd?._id }" tabindex="0"
                                                    class="dropdown-item">
                                                    <i class="metismenu-icon pe-7s-pen text-primary"
                                                        style="margin-right: 10px;"></i>{{'data.edit_removal' |
                                                    translate }}
                                                </button>
                                                
                                                <!-- <button type="button" tabindex="0"
                                                    (click)="goToNewTab(cmd._id, '/facture')" class="dropdown-item"><i
                                                        class="metismenu-icon pe-7s-print"
                                                        style="margin-right: 10px;"></i>{{'data.bill' | translate }}</button> -->
                                                <!-- <h6 tabindex="-1" class="dropdown-header">Header</h6> -->
                                                <!-- <div *ngIf="cmd.etat_cmd=='validated'">
                                                    <button type="button" tabindex="0"
                                                        class="dropdown-item" (click)="updateStatusCmd(cmd._id,'canceled')"><i
                                                            class="metismenu-icon pe-7s-close-circle"
                                                            style="margin-right: 10px;"></i>Annuler</button>
                                                </div> -->
                                                <!-- <div tabindex="-1" class="dropdown-divider"></div> -->
                                                <!-- <button type="button" tabindex="0"
                                                    class="dropdown-item">Dividers</button> -->
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <!-- <div class="d-block text-center card-footer" *ngIf="listCmds?.length > 0 && pages?.length > 1">
                        <nav aria-label="Page navigation example">
                            <ul class="pagination justify-content-center">
                                <li [ngClass]="(currentPage===0)?'page-item disabled':'page-item'">
                                    <a class="page-link" tabindex="-1" (click)="previousPage(ref, numero, datein, datefin)">
                                        <i class="nav-link-icon pe-7s-angle-left-circle"></i> Précédent</a>
                                </li>
                                <div *ngFor="let p of pages">
                                    <li [ngClass]="(currentPage===p)?'page-item active':'page-item'">
                                        <a *ngIf="currentPage!==p" class="page-link" (click)="selectPage(p, ref, numero, datein, datefin)">{{p+1}}</a>

                                        <span *ngIf="currentPage===p" class="page-link">
                                            {{p+1}}
                                            <span class="sr-only">(current)</span>
                                        </span>
                                    </li>
                                </div>

                                <li [ngClass]="(currentPage===totalPages-1)?'page-item disabled':'page-item'">
                                    <a class="page-link" tabindex="-1" (click)="nextPage(ref, numero, datein, datefin)">Suivant <i class="nav-link-icon pe-7s-angle-right-circle"></i></a>
                                </li>
                            </ul>
                        </nav> -->

                    <!-- <button class="mr-2 btn-icon btn-icon-only btn btn-outline-danger"><i
                                class="pe-7s-trash btn-icon-wrapper"> </i></button>
                        <button class="btn-wide btn btn-success">Save</button> -->
                    <!-- </div> -->
                </div>
            </div>
        </div>
    </div>
</div>