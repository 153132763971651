import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ClientsService } from 'src/app/services/clients/clients.service';
import { CommandesService } from 'src/app/services/commandes/commandes.service';

@Component({
  selector: 'app-returned-commandes',
  templateUrl: './returned-commandes.component.html',
  styleUrls: ['./returned-commandes.component.css']
})
export class ReturnedCommandesComponent implements OnInit {

  returnedCmds: string = ""
  labelReturned: string = ""
  listCmds: any = []
  
  currentPage: number = 0
  totalPages: number = 1
  pages: Array<number> = [];
  pagesDisplaied: Array<number> = [];

  statusCmd: any = []

  constructor(private route: ActivatedRoute,
    private cmdsService: CommandesService,
    private clientService: ClientsService) { }

  ngOnInit(): void {
    this.cmdsService.getLabeledStatus().then(
      (lst) => {
        this.statusCmd = lst
      }
    )

    this.route.queryParams.subscribe(async (params) => {
      this.returnedCmds = params.returned_by;
      await this.setLabelReturned()
      this.listCmdsClient()
    });
  }

  goToNewTab(idCmd, route) {
    this.cmdsService.goToNewTab(idCmd, route)
  }

  async listCmdsClient() {
    let userId = await this.clientService.getIdUser()
    await this.cmdsService.allCmdsClientByStatus(this.currentPage, userId, ["returned"]).then((res: any) => {
      
      if (res) {
        this.listCmds = res.items;
        this.totalPages = res.totalPages
        this.currentPage = res.currentPage
        this.pages = Array.from(Array(res.totalPages)).map((x, i) => i )
        this.splicePages()
      }
    });

    this.listCmds = this.listCmds.filter(cmd => cmd.returned_cmd == this.returnedCmds);
  }

  splicePages() {
    // console.log("currentPage : ")
    //     console.log(this.currentPage)
    if(this.pages.length > 10) {
      // if(this.currentPage < this.pages.length-10) {
      //   this.pagesDisplaied = this.pages.splice(this.currentPage, 10);
      //   console.log("if 1 pagesDisplaied : ")
      //   console.log(this.pagesDisplaied)
      // } else 
      if(this.currentPage < 5) {
        this.pagesDisplaied = this.pages.splice(0, 10);
        // console.log("if 1 pagesDisplaied : ")
        // console.log(this.pagesDisplaied)
      } else if(this.currentPage >= 5 && this.currentPage < this.pages.length-5) {
        this.pagesDisplaied = this.pages.splice(this.currentPage-4, 10);
        // console.log("if 2 pagesDisplaied : ")
        // console.log(this.pagesDisplaied)
      } else {
        this.pagesDisplaied = this.pages.splice(this.pages.length-10, 10);
        // console.log("else pagesDisplaied : ")
        // console.log(this.pagesDisplaied)
      }
    } else {
      this.pagesDisplaied = this.pages
    }
  }

  nextPage() {
    this.currentPage++
    this.listCmdsClient();
  }

  previousPage() {
    this.currentPage--
    this.listCmdsClient();
  }

  selectPage(page) {
    this.currentPage = page
    this.listCmdsClient();
  }

  setLabelReturned() {
    if (this.returnedCmds == "returned_exp") {
      this.labelReturned = "Retour expéditeur"
    } else if (this.returnedCmds == "returned_client") {
      this.labelReturned = "Retour client"
    } else if (this.returnedCmds == "return_request") {
      this.labelReturned = "En cours de retour"
    } else {
      this.labelReturned = "Retour reçu"
    }
  }

}
