import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import jwt_decode from "jwt-decode";

const providerTokenValue = "token-provider"

@Injectable({
  providedIn: 'root'
})
export class ClientsService {

  constructor(private http: HttpClient) { }

  async getFraisClient(idClient) {
    return await new Promise((slv) => {
      this.http.post(environment.apiGetFraisClient + "/" + idClient, {}).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  getIdUser() {
    var token = localStorage.getItem(providerTokenValue)
    // console.log("tokennnnnnnnn : ");

    if (token && token != null) {
      var decoded = jwt_decode(token);
      // console.log("idddddddddddddddddduser")
      // console.log(decoded["id"])
      return decoded["id"]
    } else return null
  }

  getUserInfos() {
    var token = localStorage.getItem(providerTokenValue)


    //console.log("tokennnnnnnnn : ");
    //console.log(token);

    return new Promise((solve) => {
      if (token && token != null) {
        var decoded = jwt_decode(token);

        this.http.post(environment.apiInfosUser + "/" + decoded["id"], {}).subscribe((res: any) => {
          //console.log("hajerrrrrrrrrrrrrrrrrrrrrr get info user");
          //console.log(res);
          if (res && res.response && res.data) {
            solve(res.data);
          } else
            solve(true);
        },
          (error) => {
            // console.log("Cmd error")
            // console.log(error)
            solve(false);
          })
      } else {
        solve(false);
      }
    });

    // let user = localStorage.getItem('userdetails');
    // if (user && JSON.parse(user))
    //   return JSON.parse(user);

    // return null;
  }

  isConnected() {
    var token = localStorage.getItem(providerTokenValue)
    // console.log("token : " + token)
    if (token)
      return true;

    return false
  }

  saveUserCreds(usercreds) {
    localStorage.setItem(providerTokenValue, JSON.stringify(usercreds.token));
  }

  login(creds) {
    console.log("creds");
    console.log(creds);
    return new Promise((solve) => {
      this.http.post(environment.apiUsersAuth, creds).subscribe((res: any) => {
        console.log("hajerrrrrrrrrrrrrrrrrrrrrr");
        console.log(res);
        if (res) {
          if (res.response && res.data) {
            this.saveUserCreds(res.data);
            solve(true);
          } else {
            solve(false);
          }
        }
      })
    });
  }

  deconnexion() {
    localStorage.removeItem(providerTokenValue);
  }

  async allCmdsNotPaiedNotReturned(idClient) {
    return await new Promise((slv) => {
      this.http.post(environment.apiCmdsNotPaiedAndNotReturned, { client: idClient }).subscribe(
        (data: any) => {
          // console.log("allCmdsNotPaiedNotReturned")
          // console.log(data)
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  async accountsClient(idClient) {
    return await new Promise((slv) => {
      this.http.post(environment.apiAccountsClient, { client: idClient }).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  async addAccountClient(idClient, total, account_exp, account_logistic, cmds) {
    return await new Promise((slv) => {
      this.http.post(environment.apiAddAccountClient, {
        client: idClient,
        total: total,
        account_exp: account_exp,
        account_logistic: account_logistic,
        cmds: cmds,
        isDemande: true
      }).subscribe(
        (data: any) => {
          // console.log("dataaaaaaaa : ")
          // console.log(data)
          if (data && data.response) {
            slv(data.response);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  async hasDemandeClient(idClient) {
    return await new Promise((slv) => {
      this.http.post(environment.apiHasDemandeClient, {
        client: idClient
      }).subscribe(
        (data: any) => {
          // console.log("dataaaaaaaa : ")
          // console.log(data)
          if (data.response) {
            slv(data.response);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  setLanguage(value: any) {
    localStorage.setItem('lang', value);
  }
  getLanguage() {
    var lang = localStorage.getItem('lang')
    if (!lang) {
      this.setLanguage('en')
      lang = localStorage.getItem('lang')
    }
    return lang
  }
}
