<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-display2 icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>Virements
                <div class="page-title-subheading">Liste des virements du client.
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row space">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="card-header">Virements
                <div class="btn-actions-pane-right">
                </div>
            </div>
            <div class="table-responsive">
                <div class="budget-price justify-content-center" *ngIf="listVirements?.length === 0">
                    <h5 class="text-center">Aucune virement du client {{client?.company_name}} </h5>
                </div>
                <table class="align-middle mb-0 table table-borderless table-striped table-hover"
                    *ngIf="listVirements?.length > 0">
                    <thead>
                        <tr>
                            <th class="text-center">#</th>
                            <th class="text-left">Expéditeur</th>
                            <th class="text-center">Total</th>
                            <th class="text-center">Compte expéditeur</th>
                            <th class="text-center">Compte logistic</th>
                            <th class="text-center">Date de virement</th>
                            <th class="text-center">Etat</th>
                            <th class="text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let virment of listVirements, let index = index" [class]="(index==selectedVirement)?'row-selected':''">
                            <td class="text-center text-muted">#{{index+1}}</td>
                            <td class="text">{{virment.client.company_name}}</td>
                            <td class="text-center">{{virment.total | number:'0.3'}} DT</td>
                            <td class="text-center">{{virment.account_exp | number:'0.3'}} DT</td>
                            <td class="text-center">{{virment.account_logistic | number:'0.3'}}</td>
                            <td class="text-center">{{virment.date_action | date:'d MMM y'}}<br>
                                <b>{{virment.date_action | date:'HH:mm'}}</b></td>
                            <td class="text-center">
                                <div *ngIf="virment.etat_account == 'demande'" class="badge badge-warning">
                                    EN COURS DE PAIEMENT</div>
                                    <div *ngIf="virment.etat_account == 'paied'" class="badge badge-success">
                                        PAYÉE</div>
                            </td>
                            <td class="text-center">
                                <button class="btn-wide btn btn-primary" (click)="showVirement(virment, index)">Détail</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- <div class="d-block text-center card-footer" *ngIf="listVirements?.length > 0">
                <button class="mr-2 btn-icon btn-icon-only btn btn-outline-danger"><i
                        class="pe-7s-trash btn-icon-wrapper"> </i></button>
                <button class="btn-wide btn btn-success">Save</button>
            </div> -->
        </div>
    </div>
</div>