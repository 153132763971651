<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-cash icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>Détail virement
                <div class="page-title-subheading">Détail de virement du client.
                </div>
            </div>
        </div>
        <div class="page-title-actions" *ngIf="listCmdsLivredReturned?.length > 0 && totalCmdsLivred>0 && !hasDemande">
            <div class="d-inline-block dropdown">
                <button type="button" (click)="demandePaiement()"
                    class="btn btn-warning">
                    <span class="btn-icon-wrapper pr-2 opacity-7">
                        <i class="fa fa-business-time fa-w-20"></i>
                    </span>
                    Demande de paiement
                </button>
                <!-- <div tabindex="-1" role="menu" aria-hidden="true"
                    class="dropdown-menu dropdown-menu-right">
                    <ul class="nav flex-column">
                        <li class="nav-item">
                            <a href="javascript:void(0);" class="nav-link">
                                <i class="nav-link-icon lnr-inbox"></i>
                                <span>
                                    Inbox
                                </span>
                                <div class="ml-auto badge badge-pill badge-secondary">86</div>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0);" class="nav-link">
                                <i class="nav-link-icon lnr-book"></i>
                                <span>
                                    Book
                                </span>
                                <div class="ml-auto badge badge-pill badge-danger">5</div>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0);" class="nav-link">
                                <i class="nav-link-icon lnr-picture"></i>
                                <span>
                                    Picture
                                </span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a disabled href="javascript:void(0);" class="nav-link disabled">
                                <i class="nav-link-icon lnr-file-empty"></i>
                                <span>
                                    File Disabled
                                </span>
                            </a>
                        </li>
                    </ul>
                </div> -->
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-3"></div>
    <div class="col-md-6">
        <table class="mb-0 table table-dark">
            <thead>
                <tr>
                    <th>#</th>
                    <th>05 Jan 2021</th>
                    <th>Quantité & cout</th>
                    <th>Montant</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th scope="row">1</th>
                    <td>Frais de livraison</td>
                    <td class="text-center">{{nbrCmdsInterne}} | {{frais?.frais_interne}} DT</td>
                    <td class="text-right">{{fraisLivred}}</td>
                </tr>
                <tr *ngIf="nbrCmdsExterne > 0">
                    <th scope="row">-</th>
                    <td>Frais de livraison externe</td>
                    <td class="text-center">{{nbrCmdsExterne}} | {{frais?.frais_externe}} DT</td>
                    <td class="text-right">{{fraisLivredExterned}}</td>
                </tr>
                <tr>
                    <th scope="row">2</th>
                    <td>Frais de retour</td>
                    <td class="text-center">{{listCmdsReturned.length}} | {{frais?.frais_retour}} DT
                    </td>
                    <td class="text-right">{{fraisReturned}}</td>
                </tr>
                <tr>
                    <th scope="row"></th>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <th scope="row">3</th>
                    <td>Total de remboursement</td>
                    <td></td>
                    <td class="text-right">{{totalCmdsLivred | number:'0.3'}} DT</td>
                </tr>
                <tr>
                    <th scope="row">4</th>
                    <td>Total montant à payer</td>
                    <td></td>
                    <td class="text-right">{{totalCmdsLivred - (fraisLivred + fraisLivredExterned + fraisReturned) | number:'0.3'}} DT</td>
                </tr>
                <tr>
                    <th scope="row">5</th>
                    <td>Total frais de livraison</td>
                    <td></td>
                    <td class="text-right">{{(fraisLivred + fraisLivredExterned + fraisReturned) | number:'0.3'}} DT</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="col-md-3"></div>
</div>

<div class="row space">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="card-header">Enlèvements
                <div class="btn-actions-pane-right">
                </div>
            </div>
            <div class="card-body">
                <div class="tab-content">
                    <div class="table-responsive">
                        <div class="budget-price justify-content-center"
                            *ngIf="listCmdsLivredReturned?.length === 0">
                            <h5 class="text-center">Aucun enlèvement {{statusCmds}} </h5>
                        </div>
                        <table
                            class="align-middle mb-0 table table-borderless table-striped table-hover"
                            *ngIf="listCmdsLivredReturned?.length > 0">
                            <thead>
                                <tr>
                                    <th class="text-center">#</th>
                                    <th class="text-left">Référence</th>
                                    <th class="text-center">Date Création</th>
                                    <th class="text-center">Nom Client<br>&<br>Téléphone</th>
                                    <th class="text-center">Ville</th>
                                    <th class="text-center">Produits<br>
                                        Quantité - Nom produit</th>
                                        <th class="text-center">Total Enlèvement</th>
                                    <th class="text-center">Status</th>

                                    <!-- <th class="text-center">Actions</th> -->
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let cmd of listCmdsLivredReturned, let index = index">
                                    <td class="text-center text-muted">#{{index+1}}</td>
                                    <td class="text">{{cmd.ref_facture_cmd}}</td>
                                    <td class="text-center">
                                        {{cmd.date_creation_cmd | date:'d MMM y'}}<br>
                                        <b>{{cmd.date_creation_cmd | date:'HH:mm'}}</b>
                                    </td>
                                    <td class="text-center">
                                        <div class="widget-content p-0">
                                            <div class="widget-content-wrapper">
                                                <div class="widget-content-left flex2">
                                                    <div class="widget-heading">
                                                        {{cmd.user_destination.nom_destination}}</div>
                                                    <div class="widget-subheading opacity-7">
                                                        {{cmd.user_destination.numtel_destination}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>

                                    <td class="text-center">
                                        {{cmd.user_destination.adresse_destination}}<br>
                                        <strong>{{cmd.destination_cmd}}</strong>
                                    </td>
                                    <td>
                                        <div *ngFor="let prd of cmd.products, let index = index">
                                            {{prd.quantite}} - {{prd.nom_prod}}
                                        </div>
                                    </td>
                                    <td class="text-center">{{cmd.total_ca_cmd + cmd.frais_livraison | number:'0.3'}} DT</td>
                                    
                                    <td class="text-center">
                                        <div class="badge badge-{{statusCmd[cmd.etat_cmd].color}}">
                                            {{statusCmd[cmd.etat_cmd].label}}</div>
                                    </td>
                                    <!-- <td class="text-center">
                                <button [routerLink]="['/detail-cmd']" [queryParams]="{ cmd: cmd._id }"
                                    type="button" id="PopoverCustomT-1"
                                    class="btn btn-primary btn-sm">Details</button>
                                <button [routerLink]="['/facture']" [queryParams]="{ cmd: cmd._id }"
                                    type="button" id="PopoverCustomT-1"
                                    class="btn btn-primary btn-sm">Facture</button>
                            </td> -->
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!-- <div class="d-block text-center card-footer" *ngIf="listCmdsLivredReturned?.length > 0">
                        <button class="mr-2 btn-icon btn-icon-only btn btn-outline-danger"><i
                                class="pe-7s-trash btn-icon-wrapper"> </i></button>
                        <button class="btn-wide btn btn-success">Save</button>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>