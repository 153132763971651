import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class SweetalertService {

  swalWithBootstrapButtons: any = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'me-2 btn btn-danger'
    },
    buttonsStyling: false
  })

  constructor() { }

  confirmActionBtn(msg: any) {
    return this.swalWithBootstrapButtons.fire({
      title: 'Êtes-vous sûr?',
      text: msg, //"Vous ne pourrez pas revenir en arrière !",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: "Oui, d'accord!",
      cancelButtonText: 'Non, annuler!',
      reverseButtons: true
    })
  }

  // alertSuccess(text: any) {
  //   return this.swalWithBootstrapButtons.fire({
  //     title: "Succès",
  //     text: text,
  //   })
  // }
  alertSuccess(text: any) {
    return this.swalWithBootstrapButtons.fire(
      "Succès",
      text,
      'success'
    )
  }

  alertConfirmed(msg: any) {
    return this.swalWithBootstrapButtons.fire(
      'Supprimé!',
      msg, //'Votre fichier a été supprimé.',
      'success'
    )
  }

  alertProblem(msg: any) {
    return this.swalWithBootstrapButtons.fire(
      'Attention!',
      msg,
      'warning'
    )
  }

  alertCanceled() {
    return this.swalWithBootstrapButtons.fire(
      'Annulé',
      'Votre fichier est en sécurité :)',
      'error'
    )
  }
}
